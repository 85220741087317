.homeFifthCenterSections {
  margin-bottom: 80px;
  //MOBILE
  @media (max-width: 768px) {
    margin-bottom: 180px;
  }
}

.homeFifth {
  @apply pb-40 flex items-center justify-center gap-5;
  padding-top: 168px;
  .orangeContainer {
    @apply bg-nudos-orange z-20 select-none text-base leading-4 font-extrabold;
    width: 500px;
    height: 277px;
    border-radius: 14px;
  }

  .leftSection {
    .title {
      @apply leading-5;
      margin-top: 54px;
      margin-left: 25px;
      font-size: 22px;
      .nudosText {
        @apply relative text-white;
        .curvyArrowUnderline {
          @apply absolute;
          min-width: 100px;
          transform: scaleY(2);
          left: 0px;
          bottom: -10px;
        }
      }
    }

    .mapAndBlackbox {
      @apply relative flex;
      margin-left: 22px;
      gap: 64px;
      .blackBox {
        @apply bg-nudos-text-color rounded text-xs leading-4 text-white text-center font-normal;
        width: 242px;
        height: 91px;
        margin-top: 56px;
        padding: 12px 36px;
        box-shadow: 0px 0.5px 0.5px rgba(0, 0, 0, 0.25);
      }

      .worldGlobeIcon {
        @apply relative;
        width: 170px;
        left: -6px;
        top: 8px;
      }
    }
  }

  .rightSection {
    @apply relative pl-6;
    .rightTitle {
      @apply mb-5 pt-14 text-xl leading-7;
      width: 250px;
      gap: 6px;
      .nodi,
      .blackText1,
      .blackText2 {
        @apply inline-block;
      }
      .nodi {
        @apply relative ml-1 text-white;
        .curvyArrowUnderline {
          @apply absolute;
          min-width: 56px;
          transform: scaleY(3);
          left: -10px;
          bottom: -4px;
        }
      }
      &.english {
        width: 200px;
        .nodi {
          @apply ml-0 mr-1;
          .curvyArrowUnderline {
            left: -6px;
          }
        }
      }
    }
    .flagsContainer {
      @apply grid grid-cols-7;
      gap: 13.85px;
      width: 258px;
      .flag {
        @apply bg-cover bg-center bg-no-repeat;
        border-radius: 1.4px;
        width: 27px;
        height: 22px;
      }
    }
    .mapIcon {
      @apply absolute top-0 right-0 -z-10;
    }
  }

  // LARGE SCREENS
  @media (min-width: 1420px) {
    .orangeContainer {
      @apply mx-auto;
      min-width: 578px;
      height: 320px;
    }
    .leftSection {
      @apply leading-5;
      font-size: 22px;
      .title {
        @apply ml-6;
      }
      .mapAndBlackbox {
        @apply ml-6;
        .blackBox {
          @apply mt-16 text-sm leading-5;
          min-width: 280px;
          height: 110px;
          padding: 12px 40px;
        }
        .worldGlobeIcon {
          @apply relative;
          width: 206px;
          left: -10px;
          top: -8px;
        }
      }
    }
    .rightSection {
      .rightTitle {
        @apply pt-16 leading-7;
        font-size: 22px;
        .nodi {
          .curvyArrowUnderline {
            min-width: 60px;
            transform: scaleY(3);
            left: -10px;
            bottom: -6px;
          }
        }
      }
      .flagsContainer {
        @apply gap-4;
        width: 284px;
        .flag {
          width: 32px;
          height: 26px;
        }
      }
      .mapIcon {
        width: 295px;
      }
    }
  }

  // MOBILE
  @media (max-width: 1023px) {
    @apply block;
    padding-top: 20px;
    padding-bottom: 100px;
    .orangeContainer {
      @apply mx-auto;
      width: 376px;
      height: 206px;
    }

    .leftSection {
      @apply mb-6 text-sm;
      line-height: 14px;
      .title {
        @apply ml-4 pt-10 text-sm leading-4;
        .nudosText {
          .curvyArrowUnderline {
            min-width: fit-content;
            min-width: fit-content;
            transform: scaleY(1);
            left: -10px;
            bottom: -8px;
          }
        }
      }

      .mapAndBlackbox {
        @apply ml-4 gap-8;
        .blackBox {
          @apply mt-10 py-2 px-7;
          min-width: 180px;
          height: 68px;
          font-size: 9px;
          line-height: 12px;
        }
        .worldGlobeIcon {
          width: 132px;
          left: -2px;
          top: -8px;
        }
      }
    }

    .rightSection {
      @apply px-4;
      .rightTitle {
        @apply pt-10 pl-0 text-sm;
        width: 150px;
        line-height: 18px;
        .nodi {
          @apply ml-0 mr-1;
          .curvyArrowUnderline {
            min-width: fit-content;
            max-width: fit-content;
            transform: scaleY(1);
            bottom: -6px;
            left: -8px;
          }
        }
        &.english {
          @apply mb-4;
          .nodi {
            @apply ml-1 mr-0;
          }
        }
      }
      .flagsContainer {
        width: 220px;
        gap: 10px;
        .flag {
          width: 24px;
          height: 18px;
        }
      }
      .mapIcon {
        width: 190px;
      }
    }
  }
}
