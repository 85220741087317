.nudosTextInput {
  label {
    @apply block mb-1 text-nudos-gray-300;
    font-size: 10px;
    line-height: 10px;
  }
  input {
    @apply w-full px-4 text-xs leading-3 border border-solid rounded-md border-nudos-gray-300;
    height: 38px;
    &::placeholder {
      @apply text-xs leading-3 text-nudos-gray-300;
    }
  }
  .errorText {
    color: red;
    margin-top: 2px;
    font-size: 10px;
    line-height: 10px;
  }
  &.filled {
    label {
      color: #383838;
    }
    input {
      @apply border-nudos-gray-500;
      color: #383838;
    }
  }
  &.error {
    label {
      color: red;
    }
    input {
      color: red;
      border-color: red;
    }
  }
  &.deactivated {
    label {
      @apply text-nudos-gray-400;
    }
    input {
      @apply bg-nudos-gray-100 border-nudos-gray-400 text-nudos-gray-400 cursor-not-allowed;
    }
  }
  &.extraSmall {
    @apply w-full;
    max-width: 80px;
    input {
      max-width: 80px;
    }
  }
  &.small {
    @apply w-full;
    max-width: 180px;
    input {
      max-width: 180px;
    }
  }
  &.medium {
    @apply w-full;
    max-width: 280px;
    input {
      max-width: 280px;
    }
  }
  &.large {
    @apply w-full;
    max-width: 380px;
    input {
      max-width: 380px;
    }
  }
}
