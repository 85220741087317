#warningPage {
  @apply w-full relative;
  .headerContainer {
    @apply w-full mb-4;
    padding: 24px 18px 0px 18px;
  }
  .textWarning {
    @apply mt-8 text-xs;
    line-height: 14px;
    color: #383838;
  }
  .containerLink {
    @apply w-full inline-flex justify-center mt-8;
  }
  .minText {
    @apply w-full text-left;
    font-size: 10px;
    line-height: 16px;
    color: #999999;
  }
  .superText {
    @apply text-nudos-green font-extrabold text-xl underline;
    line-height: 24px;
  }
  .containerImg {
    @apply w-full relative flex justify-end content-center items-center;
    height: 338px;
  }
  .boxImg {
    @apply absolute flex items-center content-center w-full justify-end;
    height: 418px;
  }
  .imgSize {
    height: 418px;
    width: 572px;
    min-height: 418px;
    max-width: 572px;
  }
  .containerButton {
    @apply w-full flex justify-center;
    height: 38px;
    bottom: 34px;
    margin-bottom: 38px;
  }
  .buttonWarning {
    @apply rounded-md text-nudos-orange text-xs font-extrabold;
    width: 340px;
    height: 38px;
    border: 1px solid #e46d57;
  }
}
