.homeSeventh {
  // MOBILE FIRST
  @apply relative;
  padding: 134px 0 122px 0;
  .connectingPath {
    @apply absolute top-0;
    left: calc(50% - 10px);
  }
  .titleContainer {
    @apply mx-auto mb-3 leading-8 font-extrabold text-center;
    width: 330px;
    font-size: 32px;
    .blackText,
    .orangeText {
      @apply inline-block;
    }
    .orangeText {
      @apply relative text-nudos-orange;
    }
  }
  .informationBoxesContainer {
    .informationBox {
      @apply mx-auto mb-6 flex items-center gap-4 bg-nudos-text-color rounded-2xl text-white;
      width: 376px;
      height: 110px;
      padding: 20px 24px 20px 28px;
      .iconContainer {
        @apply flex items-center justify-center bg-nudos-orange;
        min-width: 54px;
        max-width: 54px;
        min-height: 54px;
        max-height: 54px;
        border-radius: 10px;
      }
      .textContainer {
        @apply pt-2;
        .boxTitle {
          @apply mb-2 text-xs leading-3 font-bold;
        }
        .boxText {
          @apply text-xs font-light;
          width: 242px;
        }
      }
    }
  }

  // DESKTOP
  @media (min-width: 1024px) {
    padding: 204px 0 148px 0;
    .connectingPath {
      left: calc(50% - 160px);
      width: 342px;
    }
    .titleContainer {
      @apply relative mb-14;
      left: -80px;
      width: 656px;
      font-size: 40px;
      line-height: 44px;
      .orangeText {
        @apply ml-1;
        .curvyArrowUnderline {
          @apply absolute;
          min-width: 260px;
          max-width: 260px;
          left: -36px;
          bottom: -64px;
        }
      }
      &.english {
        width: 501px;
        .orangeText {
          .curvyArrowUnderline {
            @apply absolute;
            min-width: 180px;
            max-width: 180px;
            left: -36px;
            bottom: -44px;
          }
        }
      }
    }
    .informationBoxesContainer {
      @apply relative;
      left: -70px;
      .informationBox1 {
        @apply mb-10 relative;
        left: -188px;
      }
      .informationBox2 {
        @apply mb-0 relative;
        right: -188px;
      }
    }
  }
}
