.blogListingSkeleton {
  .blogListMainCard {
    @apply w-full h-full flex text-nudos-text-color;
    gap: 18px;

    .leftSection {
      @apply basis-3/5 rounded-md bg-nudos-gray-250 animate-pulse;
    }

    .rightSection {
      @apply basis-2/5;
      .category {
        @apply mb-3 rounded-md bg-nudos-gray-250 animate-pulse;
        width: 160px;
        height: 12px;
      }
      .postTitle {
        @apply mb-3 rounded-md bg-nudos-gray-250 animate-pulse;
        width: 300px;
        height: 24px;
      }
      .description {
        @apply mb-12 rounded-md bg-nudos-gray-250 animate-pulse;
        width: 350px;
        height: 56px;
      }
      .author {
        @apply flex gap-2;
        .authorPhoto {
          @apply w-6 h-6 rounded-full bg-nudos-gray-250 animate-pulse;
        }
        .authorNameAndRole {
          .authorName {
            @apply mb-1 rounded-md bg-nudos-gray-250 animate-pulse;
            width: 180px;
            height: 10px;
          }
          .authorRole {
            @apply rounded-md bg-nudos-gray-250 animate-pulse;
            width: 100px;
            height: 8px;
          }
        }
      }
    }
  }

  .blogListSecondaryCard {
    @apply rounded-md bg-nudos-gray-100 animate-pulse;
    width: 280px;
    height: 340px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);

    .topSection {
      @apply w-full bg-nudos-gray-250 animate-pulse;
      border-radius: 6px 6px 0px 0px;
      height: 140px;
    }
    .bottomSection {
      @apply w-full bg-nudos-gray-100 animate-pulse;
      padding: 16px 15px 16px 15px;
      height: 200px;
      .categoryAndTime {
        @apply mb-2 flex gap-1;
        .category {
          @apply rounded-md bg-nudos-gray-250 animate-pulse;
          width: 100px;
          height: 10px;
        }
        .readingTime {
          @apply rounded-md bg-nudos-gray-250 animate-pulse;
          width: 80px;
          height: 10px;
        }
      }
      .postTitle {
        @apply mb-4 rounded-md bg-nudos-gray-250 animate-pulse;
        width: 200px;
        height: 14px;
      }
      .description {
        @apply mt-4 rounded-md bg-nudos-gray-250 animate-pulse;
        width: 260px;
        height: 100px;
      }
    }
  }

  // MOBILE
  @media (max-width: 768px) {
    .blogListMainCard {
      @apply w-full h-full block;
      .leftSection {
        @apply rounded-none bg-nudos-gray-250 animate-pulse;
        width: calc(100% + 40px);
        margin: 0px 0px 28px -20px;
        height: 200px;
      }

      .rightSection {
        .description {
          @apply mb-4;
        }
      }
    }
  }
}
