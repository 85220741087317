.homeEightCenterContainer .centerSections {
  // LARGE SCREENS
  @media (min-width: 1124px) {
    max-width: 1124px;
    width: 1124px;
  }
}

.homeEight {
  @apply flex flex-row justify-between gap-12 bg-nudos-gray-100;
  margin: 0px 0px 68px 0px;
  padding: 70px 12px 68px 32px;
  .leftSection {
    .titleContainer {
      @apply relative font-extrabold;
      width: 280px;
      font-size: 40px;
      line-height: 40px;
      .titleIcon {
        @apply absolute;
        top: -140px;
        left: -60px;
        width: 114px;
        height: 115px;
      }
    }
    .subtitle {
      @apply mt-4 text-lg font-normal;
      color: #383838;
      max-width: 350px;
      line-height: 18px;
    }
  }
  .rightSection {
    @apply relative;
    width: 582px;
  }

  // LARGE SCREENS
  @media (min-width: 1025px) {
    border-radius: 20px;
    margin: 0px 0px 68px 0px;
    padding: 70px 64px 68px 84px;
    .leftSection {
      .titleContainer {
        .titleIcon {
          left: -48px;
          top: -120px;
        }
      }
    }
  }

  // MOBILE
  @media (max-width: 1023px) {
    @apply relative flex-col justify-start items-start gap-8;
    @media (min-width: 400px) {
      @apply items-center;
    }
    padding: 90px 24px 40px 24px;
    .leftSection {
      @apply mt-0 pt-0 pb-0;
      @media (min-width: 400px) {
        @apply items-center;
      }
      .titleContainer {
        @apply mx-auto leading-10 text-center;
        font-size: 40px;
        .titleIcon {
          @apply absolute;
          top: -144px;
          left: calc(50% - 60px);
        }
      }
      .subtitle {
        @apply mt-4 w-full leading-7 text-center;
        font-size: 19px;
      }
    }
    .rightSection {
      @apply w-full my-0 flex justify-center;
      @mixin daas-form-input-measures {
        @apply w-full h-full;
        max-height: 38px;
      }
      @mixin daas-form-input-styles {
        @apply mt-px bg-white border border-solid border-nudos-gray-600 rounded-md text-nudos-gray-600;
        padding-left: 10px;
        &::placeholder {
          @apply text-nudos-gray-300;
        }
      }
    }
  }
}
