@use "../../../styles/constants.scss" as constants;

.EquipmentThird {
  @apply relative w-full;
  padding-bottom: 280px;
  min-height: 10px;
  @media (min-width: 1420px) {
    padding-bottom: 380px;
    min-height: 10px;
  }
  @media (max-width: 767px) {
    @apply mt-14;
  }

  .backgroundImage {
    @apply absolute bg-center bg-no-repeat;
    background-image: url("../../../assets/images/equipament/EquipmentThird/equipmentThirdBackgroundImage.svg");
    width: 1029px;
    height: 865px;
    bottom: 80px;
    left: 0px;
    @media (max-width: 767px) {
      @apply bg-contain;
      background-image: url("../../../assets/images/equipament/EquipmentThird/largeScreensBackground.svg");
      width: 580px;
      height: 469px;
      bottom: 200px;
      left: -120px;
      transform: scale(1.1) rotate(160deg) rotateY(180deg);
    }
    @media (min-width: 1420px) {
      background-image: url("../../../assets/images/equipament/EquipmentThird/largeScreensBackground.svg");
      bottom: 80px;
      transform: scale(1.1);
    }
  }

  .logosContainer {
    @apply relative z-20 mt-14 flex items-center justify-center;
    img {
      @apply w-full h-full;
      max-width: 469px;
      max-height: 43px;
    }
    @media (max-width: 768px) {
      img {
        padding-top: 0px;
        max-width: 112px;
        max-height: 225px;
      }
    }
  }

  .titlePartners {
    @apply relative z-20 text-center font-extrabold;
    font-size: 40px;
    line-height: 60px;
    letter-spacing: -0.02em;
    color: #383838;
    @media (max-width: 768px) {
      @apply text-2xl;
    }
  }

  .subtitlePartners {
    @apply relative z-20 text-lg text-center text-black;
    line-height: 30px;
    letter-spacing: -0.02em;
    @media (max-width: 767px) {
      @apply mx-auto text-xs;
      width: 244px;
      line-height: 30px;
    }
  }
}
