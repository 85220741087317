.blogListing {
  @apply w-full;
  min-height: calc(100vh - 75px - 170px);
  padding: 24px 22px 40px 22px;

  .titleContainer {
    @apply pb-8 border-b border-solid border-nudos-gray-200 flex items-center gap-3;

    .title {
      @apply text-nudos-text-color font-extrabold;
      font-size: 40px;
      line-height: 70px;
    }

    .logoContainer {
      margin-top: 6px;
    }
  }

  .mainEntryContainer {
    @apply w-full py-10 border-b border-solid border-nudos-gray-200;
    height: 280px;
  }

  .blogListContainer {
    @apply w-full flex items-center justify-start gap-6 flex-wrap;
    padding: 48px 46px 50px 46px;
  }

  .noBlogEntries {
    @apply my-auto w-full;
    height: calc(100vh - 412px);
    padding-top: calc(0.45 * (100vh - 412px));
    .icon {
      @apply w-full mb-2 flex items-center justify-center;
      img {
        width: 57px;
        height: 54px;
      }
    }
    .text {
      @apply w-max mx-auto text-sm text-nudos-gray-300 leading-5 font-extrabold;
    }
  }
  // MOBILE
  @media (max-width: 768px) {
    padding: 8px 18px 100px 18px;
    .titleContainer {
      @apply justify-center gap-0 pb-4 border-none;

      .title {
        @apply text-2xl leading-6;
      }

      .logoContainer {
        margin-left: -2px;
        svg {
          transform: matrix(0.5, 0, 0, 0.5, 0, 0);
          -ms-transform: matrix(0.5, 0, 0, 0.5, 0, 0);
          -webkit-transform: matrix(0.5, 0, 0, 0.5, 0, 0);
        }
      }
    }

    .mainEntryContainer {
      @apply w-full h-auto pt-0 pb-8 border-b border-solid border-nudos-gray-200;
    }

    .blogListContainer {
      @apply justify-center gap-4;
      padding: 32px 0px 0px 0px;
    }

    .noBlogEntries {
      @apply my-0;
      height: auto;
      padding-top: 104px;
      padding-bottom: 104px;
    }
  }
}
