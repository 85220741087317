@use "../../../styles/constants.scss" as constants;

.equipmentSecond {
  @apply w-full;
  padding-top: 110px;
  padding-bottom: 112px;
  @media (max-width: 768px) {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .componentTitle {
    @apply mx-auto mb-14 font-extrabold text-center;
    width: 508px;
    font-size: 40px;
    line-height: 40px;
    strong {
      @apply relative text-nudos-orange;
      .curvyArrowUnderline {
        @apply absolute;
        left: -40px;
        bottom: -65px;
        min-width: 340px;
        max-width: 340px;
        transform: scaleY(0.6);
      }
      .mySpace {
        left: -125px;
      }
      .extraSpace {
        left: -40px;
      }
    }
    @media (max-width: 767px) {
      @apply mb-11;
      width: 304px;
      font-size: 24px;
      line-height: 24px;
      strong .curvyArrowUnderline {
        left: -26px;
        bottom: -42px;
        min-width: 204px;
        max-width: 204px;
        transform: scaleY(0.65);
      }
    }
  }
  .extraTitle {
    width: 581px;
  }
  .containerIcon {
    @apply relative inline-block mr-3;
    height: 12px;
  }
  .banner {
    @apply z-20 relative w-full flex bg-center bg-cover bg-no-repeat;
    height: 104px;
    @media (max-width: 767px) {
      height: 140px;
    }
    @media (min-width: 768px) and (max-width: 1100px) {
      @apply w-full mx-auto flex rounded-md bg-center bg-cover bg-no-repeat;
      max-width: 982px;
      height: 104px;
    }
  }
  .containerImg {
    @apply w-full m-auto block;
    max-width: 1024px;
    @media (max-width: 768px) {
      max-width: 100%;
    }
  }
  .logoBox {
    @apply h-full flex items-center content-center;
    margin-left: 80px;
    @media (max-width: 768px) {
      margin-left: 20px;
    }
  }
  .appleColor {
    background-color: #e8e4e4;
  }
  .titleBanner {
    @apply font-extrabold text-2xl text-black;
    line-height: 60px;
    letter-spacing: -0.02em;
    @media (max-width: 768px) {
      line-height: 18px;
      @apply text-base;
    }
  }

  .containerCards {
    @apply h-max inline-flex pt-6 pb-12 overflow-y-hidden;
    @media (max-width: 768px) {
      overflow-x: auto;
    }
  }
  .extraSpace {
    margin-bottom: 161px;
    @media (max-width: 768px) {
      @apply mb-12;
    }
  }
  .centerCards {
    @apply flex justify-center;
  }
  .cardProduct {
    @apply z-20 relative mr-5 pt-2 bg-white rounded-md border border-solid border-nudos-gray-250 cursor-pointer;
    width: 180px;
    height: 230px;
    @media (max-width: 768px) {
      min-width: 180px;
    }
    &:last-child {
      margin-right: 0px;
    }
    @media (max-width: 768px) {
      &:last-child {
        margin-right: 20px;
      }
      &:first-child {
        margin-left: 20px;
      }
    }
  }
  .headerCard {
    @apply w-full;
    height: 150px;
  }
  .footerCard {
    @apply w-full pt-2 px-2;
    height: 100px;
  }
  .minText {
    @apply text-black mb-1;
    font-size: 8px;
    line-height: 16px;
    letter-spacing: -0.02em;
  }
  .imgProduct {
    @apply w-full h-full flex items-center content-center;
    height: 75%;
  }
  .imgProps {
    @apply m-auto block;
    max-height: 90%;
    max-width: 90%;
  }
  .bodyText {
    @apply w-full;
    height: 25%;
  }
  .titleProduct {
    @apply font-extrabold text-center text-black text-xs mb-2;
    line-height: 14px;
    letter-spacing: -0.02em;
  }
  .subtitleProduct {
    @apply mb-3 mx-auto text-center text-black;
    max-width: 124px;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: -0.02em;
  }
  .orangeButton {
    @apply rounded-md w-full text-white cursor-pointer font-extrabold;
    font-size: 12px;
    line-height: 14px;
    height: 28px;
    background: constants.$nudos-orange;
    border: 1px solid constants.$nudos-orange;
  }
  .whiteButton {
    @apply rounded-md w-full cursor-pointer bg-white font-extrabold mb-2;
    font-size: 12px;
    line-height: 14px;
    height: 28px;
    color: constants.$nudos-orange;
    border: 1px solid constants.$nudos-orange;
  }
  .containerIcon {
    @apply inline-flex relative ml-1;
  }
  .styleIconsButtons {
    @apply absolute bottom-0 left-0;
    bottom: -1px;
  }
}
