@use "../../../styles/constants.scss" as constants;

@keyframes moveInFromRight {
  0% {
    transform: translateX(120px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes moveInFromLeft {
  0% {
    transform: translateX(-120px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

#nodiPlatformSecond {
  @apply relative;
  .backgroundContainerDesktop {
    @apply absolute bg-no-repeat bg-center;
    top: 180px;
    left: -96px;
    width: 1320px;
    height: 900px;
    background-image: url("../../../assets/images/NodiPlatformView/NodiPlatformSecond/NodiPlatformSecondDesktopBackground.webp");
    transform: scale(1.05);
  }
  .componentTitle {
    @apply mb-16 text-center font-extrabold;
    font-size: 40px;
    line-height: 60px;
    strong {
      @apply relative text-nudos-orange;
      .curvyArrowUnderline {
        @apply absolute;
        min-width: 208px;
        max-width: 208px;
        left: -80px;
        bottom: -48px;
      }
    }
  }
  .section1,
  .section2,
  .section3,
  .section4 {
    @apply w-full flex flex-row;
    .left {
      @apply basis-1/2 pr-5;
      padding-bottom: 20px;
    }
    .right {
      @apply basis-1/2 pl-5 border-l border-dashed border-nudos-orange;
      padding-bottom: 20px;
      border-left-width: 2px;
    }
  }
  .titleContainer {
    @apply relative mb-2 flex flex-row items-center text-lg leading-6 font-extrabold;
    gap: 10px;
  }
  p {
    @apply relative text-sm leading-4;
    max-width: 318px;
    color: #383838;
  }
  .imageContainer {
    @apply relative;
    &:not(.active) {
      @apply opacity-0;
    }
  }
  .section1,
  .section3 {
    p {
      @apply pl-5;
    }
    .imageContainer.active {
      animation: moveInFromRight 1.4s ease-out;
    }
  }
  .section2,
  .section4 {
    .nodiPlatformTextContainer p {
      @apply pr-5;
    }
    .imageContainer.active {
      @media (max-width: 1023px) {
        animation: moveInFromRight 1.4s ease-out;
      }
      @media (min-width: 1024px) {
        animation: moveInFromLeft 1.4s ease-out;
      }
    }
  }
  .section1 .left,
  .section2 .right,
  .section3 .left,
  .section4 .right {
    @apply flex flex-col items-center justify-center;
  }
  .section1 {
    .right {
      @apply relative;
      .purple-dot-container {
        @apply absolute -top-1;
        left: -6px;
      }
    }
    .imageContainer {
      left: 104px;
      width: 400px;
      height: 340px;
    }
    .nodiPlatformTextContainer {
      .argument-container {
        &:first-child {
          @apply mb-8;
        }
      }
    }
  }
  .section2 {
    .imageContainer {
      top: 40px;
      left: -21.5px;
      width: 444px;
      height: 313px;
    }
  }
  .section3 {
    .imageContainer {
      left: 32px;
      width: 413px;
      height: 316px;
    }
  }
  .section4 {
    @apply relative;
    max-height: 270px;
    .imageContainer {
      left: -8px;
      width: 486px;
      height: 324px;
    }
    .right {
      @apply relative pb-0 justify-end;
      .nodiPlatformTextContainer {
        .buttonContainer {
          margin-top: 60px;
          &:hover {
            margin-top: 58px;
          }
        }
      }
      .purple-dot-container {
        @apply absolute -bottom-1;
        left: -6px;
      }
    }
  }
  // Mobile
  @media (max-width: 1023px) {
    @apply flex flex-col;
    @media (min-width: 400px) {
      @apply items-center;
    }
    padding-bottom: 100px;
    .backgroundContainerMobile1,
    .backgroundContainerMobile2 {
      @apply absolute bg-no-repeat bg-center;
      background-image: url("../../../assets/images/NodiPlatformView/NodiPlatformSecond/nodiPlatformSecondMobileBackground.webp");
      width: 647px;
      height: 523px;
    }
    .backgroundContainerMobile1 {
      top: 100px;
      left: -308px;
      transform: scale(1.4);
    }
    .backgroundContainerMobile2 {
      top: 980px;
      left: 44px;
      transform: scale(1.3);
    }
    .componentTitle {
      @apply relative mb-8 text-center font-extrabold;
      font-size: 24px;
      line-height: 44px;
      strong {
        @apply relative text-nudos-orange;
        .curvyArrowUnderline {
          @apply absolute;
          min-width: fit-content;
          max-width: fit-content;
          left: -24px;
          bottom: -32px;
          transform: scaleY(1);
        }
      }
    }
    .content-section {
      min-width: 344px;
      max-width: 344px;
    }
    .section1,
    .section2,
    .section3,
    .section4 {
      @apply pb-5 w-full flex flex-col justify-start border-nudos-orange;
      margin-left: 18px;
      border-left-width: 2px;
      border-style: dashed;
      .left {
        @apply basis-auto p-0;
      }
      .right {
        @apply basis-auto p-0;
        border: none;
      }
      .titleContainer {
        @apply ml-3 self-start text-left text-base leading-5;
      }
      .nodiPlatformTextContainer {
        @apply w-full self-start text-left;
      }
      p {
        @apply text-xs pl-0 ml-8 tracking-tight;
        width: 304px;
        line-height: 14px;
      }
    }
    .titleContainer {
      @apply ml-4;
    }
    .section1,
    .section3 {
      @apply flex flex-col-reverse justify-end;
    }
    .section1 {
      .nodiPlatformTextContainer {
        height: 180px;
        padding-top: 40px;
        .argument-container {
          &:first-child {
            @apply mb-6;
          }
        }
      }
      .imageContainer {
        left: 40px;
        width: 287px;
        height: 223px;
      }
      p {
        max-width: 286px;
      }
    }
    .section2 {
      .nodiPlatformTextContainer {
        height: 164px;
        padding-top: 70px;
        padding-bottom: 34px;
        p {
          @apply pr-0;
          max-width: 280px;
        }
      }
      .imageContainer {
        left: 20px;
        width: 287px;
        height: 202px;
      }
    }
    .section3 {
      .nodiPlatformTextContainer {
        height: 130px;
        padding-top: 28px;
      }
      .imageContainer {
        left: 30px;
        width: 250px;
        height: 191px;
      }
      p {
        max-width: 280px;
      }
    }
    .section4 {
      min-height: 410px;
      max-width: 342px;
      .nodiPlatformTextContainer {
        height: 170px;
        padding-bottom: 60px;
      }
      .imageContainer {
        left: 10px;
        width: 360px;
        height: 240px;
      }
      .buttonContainer {
        .nudosButton {
          @apply mx-auto;
          .nodiPlatformTextContainer {
            @apply h-auto;
          }
          &:hover {
            min-width: 282px;
            min-width: 282px;
          }
        }
      }
    }
  }
}
