.spinner {
  margin: 20px auto 0;
  margin-top: 5px;
  width: 50px;
  text-align: center;
  .bounce1 {
    animation-delay: -0.32s;
  }

  .bounce2 {
    animation-delay: -0.16s;
  }
}

.spinner > div {
  width: 12px;
  height: 12px;
  opacity: 0.5;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
