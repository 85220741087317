.demoForm {
  @apply grid grid-cols-2;
  .demoFormField,
  .nudosPhoneInput,
  .nudosButton {
    max-width: 280px;
  }

  .demoFormField,
  .nudosPhoneInput {
    @apply mb-5;
  }

  .nudosPhoneInput {
    @apply w-full;
    .demoPhoneField {
      width: calc(100% - 78px);
    }
  }

  .nudosButton {
    margin-top: 14px;
  }

  // MOBILE
  @media (max-width: 1023px) {
    @apply w-full block;
    max-width: 380px;
    .demoFormField,
    .nudosPhoneInput,
    .nudosButton {
      max-width: 380px;
    }
    .nudosPhoneInput {
      @apply mb-4;
    }
    .demoFormField {
      @apply mb-4;
    }
  }
}
