.homeThird {
  @apply bg-nudos-gray-100;
  border-radius: 14px;
  // MOBILE FIRST
  .informationBoxes {
    @apply mx-auto;
    max-width: 376px;
    padding: 40px 24px 98px 24px;
    .informationBox {
      .boxIcon {
        @apply mx-auto;
        width: 89px;
        height: 127px;
      }
      .textContainer {
        .listTitle {
          @apply mb-2 text-xl leading-7 font-extrabold;
        }
        .itemsList {
          @apply pl-3 list-disc;
          .listItem {
            @apply text-sm leading-5;
          }
        }
      }
    }
    .informationBox1 {
      margin-bottom: 72px;
    }
  }

  //DESKTOP
  @media (min-width: 1024px) {
    @apply relative;
    left: -54px;
    min-width: 1132px;
    .informationBoxes {
      @apply flex items-center justify-between;
      max-width: 1024px;
      padding: 56px 10px 60px 4px;
      gap: 48px;
      .informationBox {
        @apply flex gap-6 items-center justify-center;
        .boxIcon {
          @apply mx-0;
        }
        .textContainer {
          .itemsList {
            .listItem {
              @apply text-base;
              line-height: 26px;
            }
          }
        }
      }
      .informationBox1 {
        @apply mb-0;
        min-width: 460px;
      }
    }
  }

  //LARGE SCREENS
  @media (min-width: 1200px) {
    .informationBoxes {
      padding: 56px 90px 60px 56px;
      max-width: 100%;
      &.english {
        padding-right: 120px;
      }
    }
  }
}
