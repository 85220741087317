.nudosPhoneInput {
  label {
    @apply mb-1 block text-nudos-gray-300;
    font-size: 10px;
    line-height: 10px;
  }

  .phoneInput {
    @apply flex items-center gap-2;

    .nudosSelectDropdown {
      .closedSelectInput {
        @apply px-2 gap-0 bg-white;
        width: 70px;
        .countryPlaceholderOrCurrentValue {
          @apply flex items-center;
          gap: 2px;
          .flag {
            @apply relative h-3 bg-cover bg-center;
            width: 14px;
            border-radius: 4px;
            .flagFilter {
              @apply absolute h-3;
              background-color: rgba(255, 255, 255, 0.7);
              width: 14px;
              border-radius: 4px;
            }
          }
        }
      }

      .optionsContainer {
        @apply px-2 py-3;
      }
      .optionContainer {
        @apply flex items-center gap-1;
        .flag {
          @apply h-3 bg-cover bg-center;
          width: 14px;
          border-radius: 4px;
        }
      }
    }
  }

  .errorText {
    margin-top: 2px;
    color: red;
    font-size: 10px;
    line-height: 10px;
  }

  &.filled {
    label {
      color: #383838;
    }
  }

  &.error {
    label {
      color: red;
    }
  }

  &.disabled {
    label {
      @apply text-nudos-gray-400;
    }
    .phoneInput
      .nudosSelectDropdown
      .closedSelectInput
      .currentValueOrplaceholder
      .countryPlaceholderOrCurrentValue
      .flag {
      @apply opacity-40;
    }
  }

  &.medium {
    .nudosTextInput {
      input {
        max-width: 192px;
        width: 192px;
      }
    }
    .nudosSelectDropdown {
      @apply bg-white;
      .selectContainer {
        .closedSelectInput {
          @apply w-full;
          max-width: 80px;
          width: 80px;
        }
      }
    }
  }

  &.large {
    .nudosTextInput {
      input {
        max-width: 292px;
      }
    }
    .nudosSelectDropdown {
      .selectContainer {
        .closedSelectInput {
          @apply w-full;
          max-width: 80px;
        }
      }
    }
  }
}
