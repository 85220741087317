.blogEntry {
  @apply w-full text-nudos-text-color;
  padding: 48px 123px 40px 123px;

  .entryHeader {
    @apply mx-auto pb-12;
    max-width: 580px;

    .categoryAndDate {
      @apply w-full flex gap-5 items-center justify-between text-xs leading-3 font-semibold;
      .category {
        @apply text-nudos-orange tracking-widest;
      }
      .date {
        @apply text-nudos-gray-500;
      }
    }

    .entryImage {
      @apply w-full mt-6 mb-4 rounded-md bg-nudos-gray-200 bg-cover bg-center bg-no-repeat;
      max-width: 580px;
      height: 200px;
    }

    .authorAndShare {
      @apply w-full flex gap-5 items-center justify-between;
      .author {
        @apply flex gap-2;
        .authorPhoto {
          @apply w-6 h-6 rounded-full bg-cover bg-center;
          min-width: 24px;
          min-height: 24px;
        }
        .authorNameAndRole {
          .authorName {
            @apply text-xs leading-3;
          }
          .authorRole {
            @apply text-nudos-gray-500 leading-3;
            font-size: 10px;
          }
        }
      }
    }
  }

  .entryBody {
    @apply w-full mx-auto pt-10 border-t border-solid border-nudos-gray-200;
    max-width: 780px;

    .entryTitle {
      @apply w-full mb-10 mx-auto text-center leading-8 font-extrabold;
      max-width: 580px;
      font-size: 28px;
    }

    .bodyContent {
      p {
        @apply mb-10 text-sm leading-5;
        img {
          @apply mx-auto;
        }
      }
    }
  }

  //MOBILE
  @media (max-width: 767px) {
    padding: 8px 18px 56px 18px;

    .entryHeader {
      @apply max-w-full pb-0;
      .entryImageMobile {
        @apply mt-0 mb-2 rounded-none bg-nudos-gray-200 bg-cover bg-center bg-no-repeat;
        width: calc(100% + 40px);
        margin-left: -20px;
        height: 200px;
      }

      .categoryAndDate {
        @apply leading-3;
        font-size: 10px;
      }
    }

    .entryBody {
      @apply w-full mx-auto pt-8 border-none;
      max-width: 600px;

      .entryTitle {
        @apply w-full mb-3 mx-auto text-center text-lg leading-5 font-extrabold;
        max-width: 580px;
      }

      .bodyContent {
        p {
          @apply mb-6 text-xs leading-5;
        }
      }
    }

    .author {
      @apply mb-10 flex gap-2;
      .authorPhoto {
        @apply w-6 h-6 rounded-full bg-cover bg-center;
        min-width: 24px;
        min-height: 24px;
      }
      .authorNameAndRole {
        .authorName {
          @apply text-xs leading-3;
        }
        .authorRole {
          @apply text-nudos-gray-500 leading-3;
          font-size: 10px;
        }
      }
    }

    .shareContainer {
      @apply w-full flex items-center justify-center;
    }
  }
}
