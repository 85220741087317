#nodiPlatformFirst {
  @apply max-w-full flex flex-col justify-between items-center select-none;
  padding: 54px 0px 0px 24px;
  .contentSection {
    @apply w-full relative flex gap-0;
    .leftDiv {
      @apply flex flex-col justify-start;
      flex-basis: 38%;
      margin-top: 74px;
      .nodiLogoContainer svg {
        @apply relative;
        left: 20px;
        transform: matrix(1.22, 0, 0, 1.22, 0, 0);
        -ms-transform: matrix(1.22, 0, 0, 1.22, 0, 0);
        -webkit-transform: matrix(1.22, 0, 0, 1.22, 0, 0);
      }
      .buttonsContainer {
        .animatedBlackButton {
          @apply mb-3;
        }
      }
      .nodiExplanationText {
        @apply w-screen mt-8 mb-6 text-lg;
        max-width: 388px;
        line-height: 22px;
        &.english {
          max-width: 390px;
        }
      }
    }
    .rightDiv {
      flex-basis: 62%;
      .imageContainer {
        @apply relative;
        left: 40px;
        width: 760px;
      }
    }
  }

  //LARGE SCREENS
  @media (min-width: 1280px) {
    .contentSection {
      left: -120px;
      .leftDiv {
        min-width: 520px;
        margin-top: 90px;
      }
      .rightDiv {
        flex-basis: 62%;
        .imageContainer {
          width: 800px;
          left: -20px;
        }
      }
    }
  }

  //Mobile
  @media (max-width: 767px) {
    @apply m-0;
    background-image: none;
    padding: 10px 18px 0px 18px;
    .contentSection {
      @apply w-full flex-col-reverse;
      .leftDiv {
        @apply mt-0 pl-0;
        flex-basis: auto;
        margin-bottom: 58px;
        .nodiLogoContainer svg {
          @apply relative;
          left: 0px;
          transform: matrix(1, 0, 0, 1, 0, 0);
          -ms-transform: matrix(1, 0, 0, 1, 0, 0);
          -webkit-transform: matrix(1, 0, 0, 1, 0, 0);
        }
        .nodiExplanationText {
          @apply mt-3 mb-9 text-base leading-5 tracking-tight;
          max-width: 320px;
        }
        .buttonsContainer {
          @apply w-full mx-auto;
          max-width: 380px;
        }
      }
      .rightDiv {
        flex-basis: auto;
        .imageContainer {
          max-width: 682px;
          img {
            @apply relative;
            left: -36px;
            max-height: 320px;
            max-width: 480px;
          }
        }
      }
    }
  }
}
