.blogListMainCard {
  @apply w-full h-full flex text-nudos-text-color;
  gap: 18px;

  .leftSection {
    @apply basis-3/5 rounded-md bg-cover bg-center bg-no-repeat bg-nudos-gray-200 select-none cursor-pointer;
  }

  .rightSection {
    @apply basis-2/5;
    .category {
      @apply mb-3 text-nudos-orange leading-3 font-semibold tracking-widest;
      font-size: 10px;
    }
    .postTitle {
      @apply mb-3 text-lg leading-5 font-extrabold select-none cursor-pointer;
    }
    .description {
      @apply mb-12 text-nudos-gray-500 text-xs leading-4;
    }
    .author {
      @apply flex gap-2;
      .authorPhoto {
        @apply w-6 h-6 rounded-full bg-cover bg-center;
        min-width: 24px;
        min-height: 24px;
      }
      .authorNameAndRole {
        .authorName {
          @apply text-xs leading-3;
        }
        .authorRole {
          @apply text-nudos-gray-500 leading-3;
          font-size: 10px;
        }
      }
    }
  }

  // MOBILE
  @media (max-width: 768px) {
    @apply w-full h-full block;
    .leftSection {
      margin: 0px 0px 28px 0px;
      height: 200px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    }

    .rightSection {
      .description {
        @apply mb-4;
      }
    }
  }
}
