#productInternalCard {
  @apply w-screen h-screen flex gap-0 items-center justify-center bg-white rounded-md select-none;
  z-index: 60;
  max-width: 780px;
  max-height: 447px;
  .leftSection {
    @apply p-11 relative h-full rounded-md bg-nudos-light-blue;
    flex-basis: 38%;
    opacity: 100;
    .brandIconContainer {
      @apply absolute top-4 left-4;
      &.apple {
        img {
          max-height: 16px;
          width: auto;
        }
      }
      &.lenovo {
        img {
          max-height: 16px;
          width: auto;
        }
      }
      &.steele {
        img {
          height: 25px;
          width: auto;
          max-width: auto;
        }
      }
    }
    .productImgContainer {
      @apply w-full h-full pt-2 flex items-center justify-center;
      max-width: 208px;
      max-height: 184px;
    }
    .productName {
      @apply mt-4 text-xl leading-4 font-extrabold text-center;
    }
    .pricesAndButtons {
      @apply mt-14;
      .fromText {
        @apply leading-3;
        font-size: 10px;
      }
      .whiteButton {
        @apply w-full mt-2 flex items-center justify-center gap-2 border border-solid border-nudos-orange rounded-md bg-white text-nudos-orange text-xs leading-4 font-extrabold cursor-pointer;
        height: 30px;
      }
      .orangeButton {
        @apply w-full mt-2 flex items-center justify-center gap-2 border border-solid border-nudos-orange rounded-md bg-nudos-orange text-white text-xs leading-4 font-extrabold cursor-pointer;
        height: 30px;
      }
    }
  }
  .rightSection {
    @apply relative h-full pt-11 pb-9 px-8 rounded-md;
    flex-basis: 62%;
    .productDetailsContainer {
      .productDetail {
        @apply flex items-center gap-3 border-b border-solid border-nudos-gray-200;
        height: 55px;
        .detailName {
          @apply text-xs leading-4;
          min-width: 140px;
        }
        .detailOptionsContainer {
          @apply flex gap-2;
          .optionContainer {
            @apply px-3 bg-nudos-gray-100 rounded-md font-bold;
            padding-top: 6px;
            padding-bottom: 6px;
            font-size: 10px;
            line-height: 20px;
          }
        }
      }
    }
    .descriptionLine {
      @apply mt-8 flex gap-2 items-center;
      font-size: 10px;
      line-height: 10px;
    }
    .colorsContainer {
      @apply mt-10;
      .title {
        @apply font-bold;
        font-size: 10px;
        line-height: 20px;
      }
      .colorOptionsWrapper {
        @apply mt-2 flex gap-2;
        .colorOption {
          @apply w-max px-1 flex items-center gap-1 bg-nudos-gray-100 rounded-md;
          height: 30px;
          .colorCircle {
            @apply rounded-full;
            width: 8px;
            height: 8px;
          }
          .colorName {
            @apply font-bold;
            padding-top: 6px;
            padding-bottom: 6px;
            font-size: 10px;
            line-height: 16px;
          }
        }
      }
    }
    .closingXButton {
      @apply absolute top-5 right-5 select-none cursor-pointer;
    }
  }
}
