.equipmentFirst {
  padding: 60px 0px 0px 0px;

  .componentTitle {
    @apply mx-auto font-extrabold text-center;
    width: 508px;
    margin-bottom: 68px;
    font-size: 40px;
    line-height: 40px;
    strong {
      @apply relative text-nudos-orange;
      .curvyArrowUnderline {
        @apply absolute;
        left: 88px;
        bottom: -52px;
        min-width: 244px;
        max-width: 244px;
        transform: scaleY(0.8);
      }
      .extraSpace {
        left: -20px;
      }
    }
  }

  .imageContainer {
    @apply flex items-center justify-center;
    .remoteWorkSetup {
      width: 927px;
      height: 367px;
    }
  }

  // MOBILE
  @media (max-width: 767px) {
    padding: 24px 0px 92px 0px;

    .componentTitle {
      @apply mb-11;
      width: 250px;
      font-size: 24px;
      line-height: 24px;
      strong .curvyArrowUnderline {
        left: -26px;
        bottom: -48px;
        min-width: 238px;
        max-width: 238px;
        transform: scaleY(0.65);
      }
    }

    .imageContainer {
      .remoteWorkSetup {
        @apply relative;
        min-width: 618px;
        max-width: 618px;
        min-height: 568px;
        max-height: 568px;
      }
    }
  }
}
