.blogEntrySkeleton {
  .entryHeader {
    @apply mx-auto pb-12;
    max-width: 580px;

    .categoryAndDate {
      @apply w-full flex gap-5 items-center justify-between;
      .category,
      .date {
        @apply rounded-md bg-nudos-gray-250 animate-pulse;
        width: 160px;
        height: 12px;
      }
      .date {
        width: 90px;
      }
    }

    .entryImage {
      @apply w-full mt-6 mb-4 rounded-md bg-nudos-gray-250 animate-pulse;
      max-width: 580px;
      height: 200px;
    }

    .authorAndShare {
      @apply w-full flex gap-5 items-center justify-between;
      .author {
        @apply flex gap-2;
        .authorPhoto {
          @apply w-6 h-6 rounded-full bg-nudos-gray-250 animate-pulse;
        }
        .authorNameAndRole {
          .authorName {
            @apply mb-1 rounded-md bg-nudos-gray-250 animate-pulse;
            width: 180px;
            height: 10px;
          }
          .authorRole {
            @apply rounded-md bg-nudos-gray-250 animate-pulse;
            width: 100px;
            height: 8px;
          }
        }
      }
      .shareOnSocialMedia {
        @apply flex gap-2;
        .circle {
          @apply w-4 h-4 rounded-full bg-nudos-gray-250 animate-pulse;
        }
      }
    }
  }

  .entryBody {
    @apply w-full mx-auto pt-10 border-t border-solid border-nudos-gray-200;
    max-width: 780px;

    .entryTitle {
      @apply mb-10 mx-auto;
      width: 580px;
      .line {
        @apply mb-2 mx-auto h-8 rounded-md bg-nudos-gray-250 animate-pulse;
        width: 440px;
        &:first-of-type {
          width: 520px;
        }
      }
    }

    .bodyContent {
      .paragraph {
        @apply mb-10 rounded-md bg-nudos-gray-200 animate-pulse;
        width: 780px;
        height: 260px;
      }
    }
  }

  //MOBILE
  @media (max-width: 767px) {
    .entryHeader {
      @apply max-w-full pb-0;
      .entryImageMobile {
        @apply w-full mt-6 mb-4 rounded-md bg-nudos-gray-250 animate-pulse;
        width: calc(100% + 40px);
        margin-left: -20px;
        height: 200px;
      }
      .categoryAndDate {
        .category,
        .date {
          height: 10px;
        }
      }
    }

    .entryBody {
      @apply w-full max-w-full mx-auto pt-8 border-none;

      .entryTitle {
        @apply w-full mb-10 mx-auto;
        max-width: 580px;
        .line {
          @apply mb-2 mx-auto h-5 rounded-md bg-nudos-gray-250 animate-pulse;
          width: 70%;
          &:first-of-type {
            width: 90%;
          }
        }
      }

      .bodyContent {
        .paragraph {
          @apply mb-6 mx-auto rounded-md bg-nudos-gray-200 animate-pulse;
          width: 95%;
          height: 260px;
        }
      }
    }

    .author {
      @apply mb-10 flex gap-2;
      .authorPhoto {
        @apply w-6 h-6 rounded-full bg-nudos-gray-250 animate-pulse;
      }
      .authorNameAndRole {
        .authorName {
          @apply mb-1 rounded-md bg-nudos-gray-250 animate-pulse;
          width: 180px;
          height: 10px;
        }
        .authorRole {
          @apply rounded-md bg-nudos-gray-250 animate-pulse;
          width: 100px;
          height: 8px;
        }
      }
    }

    .shareContainer {
      @apply w-full flex items-center justify-center;
      .shareOnSocialMedia {
        @apply flex gap-2;
        .circle {
          @apply w-6 h-6 rounded-full bg-nudos-gray-250 animate-pulse;
        }
      }
    }
  }
}
