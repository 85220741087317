@use "constants";

@font-face {
  font-family: "Causten";
  src: local("causen"),
    url("../assets/fonts/Causten\ Round\ Complete\ Family/Trustha-CaustenRoundThin.otf")
      format("opentype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Causten";
  src: local("causen"),
    url("../assets/fonts/Causten\ Round\ Complete\ Family/Trustha-CaustenRoundExtraLight.otf")
      format("opentype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Causten";
  src: local("causen"),
    url("../assets/fonts/Causten\ Round\ Complete\ Family/Trustha-CaustenRoundLight.otf")
      format("opentype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Causten";
  src: local("causen"),
    url("../assets/fonts/Causten\ Round\ Complete\ Family/Trustha-CaustenRoundRegular.otf")
      format("opentype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Causten";
  src: local("causen"),
    url("../assets/fonts/Causten\ Round\ Complete\ Family/Trustha-CaustenRoundMedium.otf")
      format("opentype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Causten";
  src: local("causen"),
    url("../assets/fonts/Causten\ Round\ Complete\ Family/Trustha-CaustenRoundSemiBold.otf")
      format("opentype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Causten";
  src: local("causen"),
    url("../assets/fonts/Causten\ Round\ Complete\ Family/Trustha-CaustenRoundBold.otf")
      format("opentype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Causten";
  src: local("causen"),
    url("../assets/fonts/Causten\ Round\ Complete\ Family/Trustha-CaustenRoundExtraBold.otf")
      format("opentype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Causten";
  src: local("causen"),
    url("../assets/fonts/Causten\ Round\ Complete\ Family/Trustha-CaustenRoundBlack.otf")
      format("opentype");
  font-weight: 900;
  font-style: normal;
}

$alert-width: 50vw;
$alert-extra-padding: 50px;

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
label,
input,
strong,
div,
section,
button {
  font-family: Causten #{!important};
  &:focus {
    outline: none !important;
  }
}

%moduleContainer {
  @apply px-4;
}
%center {
  @apply flex justify-center items-center;
}
%text-bordered-secondary {
  -webkit-text-stroke: 1px constants.$nudos-orange;
  color: transparent;
}
%text-bordered-primary {
  -webkit-text-stroke: 1px constants.$nudos-green;
  color: transparent;
}
%demo-wait-list {
  width: 100%;
  @apply justify-center flex flex-col md:flex-row-reverse items-center mt-8 md:mt-0;
  h1 {
    @apply font-bold text-3xl md:text-7xl text-center pb-2;
    color: constants.$nudos-green;
  }
  p {
    @apply text-xl text-center;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    min-height: 500px;

    #formulary {
      padding-right: 16px;
    }

    h1 {
      font-size: 48px;
      line-height: 46px;
    }

    p {
      font-size: 22px;
      line-height: 26px;
    }
  }

  @media (min-width: 1024px) and (max-width: 1440px) {
    min-height: 608px;
    padding-bottom: 48px;
    padding-top: 0px;

    #formulary {
      padding-right: 40px;
    }

    h1 {
      font-size: 56px;
      line-height: 62px;
    }

    p {
      font-size: 28px;
      line-height: 32px;
    }
  }

  @media (min-width: 1440px) {
    min-height: 708px;
    padding-bottom: 154px;
    padding-top: 52px;

    #formulary {
      padding-right: 0px;
    }

    h1 {
      font-size: 50px;
    }

    p {
      font-size: 40px;
      line-height: 46px;
    }
  }

  .relative-container {
    @apply relative w-full self-start mt-8 md:mt-0 mb-14 md:mb-0;
    min-height: 236px;
    height: 100%;
    width: 85%;
    @media (min-height: 400px) and (max-width: 768px) {
      @apply flex w-full items-center justify-center mt-8 md:mt-0;
    }

    img {
      @apply absolute left-0;
      @media (min-width: 400px) and (max-width: 768px) {
        @apply static flex self-center justify-self-center;
      }
      @media (min-width: 768px) and (max-width: 1024px) {
        padding-top: 90px;
      }
      @media (min-width: 1024px) and (max-width: 1440px) {
        padding-top: 130px;
      }
      @media (min-width: 1440px) {
        padding-top: 100px;
      }
    }
  }
}

.rnc__notification-container--top-right {
  top: 54px !important;
  @media (min-width: 1420px) {
    right: calc(22px + calc(0.8 * calc(100vw - 1420px))) !important;
  }
}
.rnc__notification {
  width: auto !important;
}
.rnc__notification-item {
  box-shadow: none !important;
}

.floating-help-icon {
  @apply fixed bottom-12 right-12 select-none cursor-pointer z-50;
  .expandedIcon,
  .contractedIcon {
    transform: scale(1.5);
  }
  .floatClose {
    @apply -right-8 absolute;
    top: -32px;
    height: 16px;
    width: 16px;
  }
}

.App {
  @apply w-screen overflow-x-hidden text-nudos-text-color;
  background-color: #fff;

  #formulary {
    @apply w-screen px-4 pt-6 flex flex-col;
    h2 {
      color: constants.$nudos-orange;
      @apply text-4xl leading-6 font-bold invisible hidden md:visible md:flex;
    }
    #error-form {
      @apply text-white text-xl;
      color: #bf1650;
    }
    input {
      border: 1px solid #000000;
      box-sizing: border-box;
      border-radius: 6px;
      max-width: 378px;
      @apply px-4 w-full py-2.5 mb-2;
      &::placeholder {
        @apply text-black;
      }
    }
    input:placeholder-shown {
      border: 1px solid constants.$nudos-gray-400;
      &::placeholder {
        color: constants.$nudos-gray-400;
      }
    }

    button {
      height: 48px;
      color: constants.$nudos-orange;
      @extend %center;
      max-width: 379px;
      border: 2px solid constants.$nudos-orange;
      @apply w-full md:w-2/3 mt-4 disabled:opacity-50 bg-white rounded-md font-bold ml-auto mr-auto;
    }
    button:disabled {
      background: #f1f1f1;
      color: constants.$nudos-gray-400;
      @apply border-none;
    }
    section {
      @apply mt-5 w-full flex flex-col justify-center items-center;
    }
    div {
      @apply md:flex;
      section {
        @apply md:w-1/2;
        &:first-child {
          @apply md:pr-3;
        }
        &:last-child {
          @apply md:pl-3;
        }
      }
    }
    a {
      color: constants.$nudos-orange;
      @apply font-bold text-base;
    }
  }

  footer {
    @apply w-screen px-6 py-8 justify-between relative;
    .flexAdjust {
      margin-top: 24px;
      flex-direction: initial;
      justify-content: center;
      width: 100% !important;
      flex-basis: auto !important;
      margin-left: 0% !important;
    }
    .whiteText {
      text-align: center;
      color: white;
      @apply font-extrabold;
    }
    .blockText {
      display: block;
    }
    background-color: constants.$nudos-green;
    section {
      @apply flex;
      svg {
        @apply mr-2;
      }
    }

    div {
      @apply w-3/12 flex flex-col justify-start;
      &:first-child {
        flex-basis: content;
      }

      &:last-child {
        @apply w-3/12 lg:w-4/12 ml-10 md:ml-0;
      }
    }

    @media (max-width: 768px) {
      padding: 96px 20px 80px 20px;
      svg {
        margin-bottom: 24px;
      }

      h3 {
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
      }

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 19.2px;
      }

      svg + h3 {
        color: white;
        text-align: center;
        margin-bottom: 16px;

        + .mail-to-1 {
          color: white;
          text-align: center;
          @apply block mb-6;
        }
      }

      svg ~ p {
        @apply mt-1 mb-2 text-white text-center;
      }

      #follow-us {
        @apply mt-6 mb-4;
      }
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      height: 176px;
      gap: 10px;
      padding-left: 30px;
      padding-top: 30px;
      padding-right: 0px;
      padding-bottom: 30px;

      h3,
      p,
      .mail-to-1 {
        font-size: 16px;
        line-height: 24px;
      }

      h3 {
        @apply mt-0 font-bold;
      }
      p {
        @apply mb-1;
      }
      h3 + p {
        margin-top: 16px;
      }
      .mail-to-1 {
        margin-top: 16px;
      }

      div:last-child {
        padding-left: 20px;
      }
    }

    @media (min-width: 1024px) and (max-width: 1440px) {
      height: 170px;
      gap: 50px;
      padding-left: 20px;
      padding-top: 43px;
      padding-right: 20px;
      padding-bottom: 0px;

      h3,
      p,
      .mail-to-1 {
        color: white;
        font-size: 19px;
        line-height: 28px;
      }

      h3 {
        @apply mb-2 font-bold text-white;
      }
      p {
        @apply mb-0 text-white;
      }
      h3 + p {
        margin-top: 0px;
      }
      .mail-to-1 {
        @apply font-extrabold mb-1;
        margin-top: 0px;
      }

      div:last-child {
        padding-left: 20px;
      }
    }

    @media (min-width: 1440px) {
      height: 170px;
      gap: 70px;
      padding-left: 29px;
      padding-top: 43px;
      padding-right: 29px;
      padding-bottom: 30px;

      h3,
      p,
      .mail-to-1 {
        font-size: 16px;
        line-height: 32px;
      }

      h3 {
        @apply mt-0 font-bold;
      }
      p {
        @apply mb-2;
      }
      h3 + p {
        margin-top: 1px;
        margin-bottom: 1px;
      }

      div:last-child {
        margin-left: 126px;
      }
    }
    .floatDiv {
      position: absolute;
      right: 30px;
      bottom: 15px;
      width: 72px;
      height: 30px;
      display: flex;
    }
    .spaceIcon {
      @apply mr-3;
    }
  }

  #wait-list {
    @extend %demo-wait-list;
    h1 {
      @apply mb-5 md:mb-3;
    }

    p {
      @apply text-xl leading-5;
    }

    .relative-container {
      @apply flex justify-center md:justify-start items-center;
      img {
        @apply mt-10 md:mt-0 mb-8 md:mb-0 static justify-center md:justify-start;
      }
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      padding-bottom: 40px;
      @apply justify-between;
      h1 {
        font-size: 40px;
        @apply pb-0;
      }
      p {
        font-size: 16px;
        max-width: 270px;
        align-self: center;
      }
      #formulary {
        section {
          margin-top: 10px;
        }
      }
      .relative-container {
        max-width: 50%;
      }
    }
    @media (min-width: 1024px) and (max-width: 1440px) {
    }

    @media (min-width: 1440px) {
      padding-bottom: 98px;
      p {
        font-size: 30px;
        line-height: 30px;
        max-width: 364px;
        align-self: center;
      }
      .relative-container img {
        padding-top: 100px;
      }
    }
  }

  /* OffLanding*/
  #offLanding {
    @apply w-screen h-screen flex content-center items-center bg-white px-4 pt-1 md:px-0 md:pt-0;

    .container-off {
      @apply block m-auto text-center pb-40;
    }
    .titleText {
      color: constants.$nudos-orange;
      font-size: 40px;
      letter-spacing: -2%;
      @apply leading-10 font-extrabold mb-14;
    }
    .centerItems {
      @apply block m-auto text-center mb-4;
    }
    .firstSubtitle {
      @apply text-base leading-5 text-black mb-1;
    }
    .secondSubtitle {
      @apply text-2xl leading-7 text-black;
    }
    .blackSubtitle {
      color: constants.$nudos-green;
      @apply font-extrabold;
    }
  }

  .fullSizeScreen {
    @apply w-screen px-5;
    max-width: 1024px;
    height: calc(100vh - 74px);
  }
  .fullMinSizeScreen {
    @apply w-screen px-5;
    max-width: 1024px;
    min-height: calc(100vh - 74px);
  }
  .fullMinSizeScreenNoPadding {
    @apply w-screen;
    max-width: 1024px;
    min-height: calc(100vh - 74px);
  }
  .fullSizeScreenNoPadding {
    @apply w-screen;
    max-width: 1024px;
    height: calc(100vh - 74px);
  }
  .centerHorizontalItems {
    @apply flex justify-center;
  }

  .marginOptional {
    margin-top: 10px !important;
  }

  .newBackGround {
    background-color: white !important;
  }

  .minHeightScreens {
    min-height: 692px;
  }

  .containerSections {
    @apply w-screen;
  }
  .centerSections {
    @apply w-full block m-auto;
    max-width: 1024px;
  }

  #homeLanding,
  #equipmentLanding {
    @apply w-screen;
    max-width: 100vw;
  }
  .spaceEmpty {
    @apply w-full;
    height: 100px;
  }
  .grayBackground {
    background-color: #fafafa;
  }

  #questionsLanding {
    margin-bottom: 186px;
    @media (max-width: 768px) {
      margin-bottom: 72px;
    }
    .titleQuestions {
      color: constants.$nudos-orange;
      @apply font-extrabold text-center mb-6;
      font-size: 40px;
      line-height: 60px;
      @media (max-width: 768px) {
        font-size: 40px;
        line-height: 42px;
        letter-spacing: -0.02em;
      }
    }
    .subtitleQuestions {
      @apply text-center font-extrabold;
      margin-bottom: 40px;
      font-size: 18px;
      line-height: 24px;
      @media (max-width: 768px) {
        @apply px-2;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: -0.02em;
      }
    }
    .containerQuestions {
      max-width: 775px;
      width: 100%;
    }
    .containerIcon {
      margin-top: 30px;
      margin-bottom: 24px;
    }
    .titleAsk {
      @apply font-extrabold;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 16px;
      color: constants.$nudos-orange;
      @media (max-width: 768px) {
        font-size: 20px;
        line-height: 24px;
      }
    }
    .itemContainer {
      @apply w-full;
      min-height: 64px;
      background: #ffffff;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
      border-radius: 14px;
      padding: 18px 24px 18px 24px;
      margin-bottom: 16px;
    }
    .containerCards {
      margin-bottom: 40px;
    }
    .pricipalTitle {
      @apply w-full text-xl flex;
      line-height: 24px;
    }
    .resptText {
      @apply text-justify mt-6;
      font-size: 14px;
      line-height: 24px;
      @media (max-width: 768px) {
        font-size: 14px;
        line-height: 16px;
      }
    }
    .askContainer {
      width: calc(100% - 24px);
      @media (max-width: 768px) {
        margin-top: 5px;
        font-size: 14px;
        line-height: 16px;
      }
    }
    .titleAskContainer {
      @apply text-lg font-extrabold;
      line-height: 24px;
    }
    .iconContainer {
      width: 24px;
      height: 24px;
      @apply cursor-pointer;
    }
    .rotateImg {
      transform: rotate(180deg);
    }
  }
  .footerGeneralContainer {
    @apply w-full bg-nudos-text-color;
    height: 170px;
  }

  #customFooter {
    @apply w-full pt-8 m-auto block bg-nudos-text-color;
    max-width: 1024px;
    padding-left: 20px;
    padding-right: 20px;
    height: 170px;
    .contianerFooter {
      @apply w-full flex h-full;
    }
    .sizeLogo {
      @apply h-full pt-4;
      width: 141px;
    }
    .containerInfo {
      @apply h-full;
      width: calc(100% - 219px);
      .simpleText {
        @apply text-xs text-white;
        line-height: 19px;
      }
      .containerIcons {
        @apply inline-flex mt-3 cursor-pointer;
      }
      .linkedin {
        @apply ml-14;
      }
      .boldText {
        @apply font-extrabold text-white text-sm;
        line-height: 17px;
      }
      .infoBox {
        @apply w-full h-full flex;
      }
      .centerItems {
        @apply m-auto block h-full;
      }
    }
    .containerEmail {
      @apply mt-4;
      max-width: 256px;
      width: 100%;
      height: 48px;
      .containerInput {
        @apply w-full h-full flex bg-white rounded-md;
        border: 1px solid constants.$nudos-gray-400;
      }
      .emailIcon {
        @apply h-full cursor-pointer;
        width: 31px;
      }
      .emailInput {
        @apply w-full h-full rounded-md px-3 text-xs;
        width: calc(100% - 31px);
        border: none;
        outline: none;
      }
      .positionIcon {
        margin-top: 17px;
      }
    }
    .sizeSocialIcons {
      @apply h-full relative cursor-pointer;
      width: 78px;
      .containerIcons {
        @apply flex absolute bottom-4 right-0;
        .linkedin {
          margin-left: 17px;
        }
      }
    }
  }
  #customFooterMobile {
    @apply w-full px-10 bg-nudos-text-color;
    min-height: 513px;
    padding-top: 40px;
    padding-bottom: 30px;
    .logo {
      @apply mb-10;
    }
    .centerText {
      @apply text-center;
    }
    .title {
      @apply text-white text-base font-extrabold;
      line-height: 19px;
    }
    .subtitle {
      @apply text-white text-base;
      line-height: 19px;
    }
    .containerInput {
      input::placeholder {
        @apply text-xs;
        line-height: 14px;
        color: #ececec;
      }
      @apply rounded-md bg-white flex m-auto;
      max-width: 256px;
      width: 100%;
      height: 48px;
      border: 1px solid constants.$nudos-gray-400;
    }
    .sizeInput {
      @apply px-3 rounded-md;
      width: calc(100% - 31px);
      outline: none;
    }
    .emailIcon {
      @apply h-full cursor-pointer;
      width: 31px;
    }
    .socialIcons {
      @apply flex justify-center mt-10;
    }
    .extraSpace {
      @apply w-6;
    }
    .positionIcon {
      margin-top: 17px;
    }
  }

  #floatButton {
    @apply right-5 z-10;
    top: 454px;
    width: 254px;
    height: 117px;
    background: #383838;
    box-shadow: 0px 4px 4px #828282;
    border-radius: 6px;
    padding: 17px 20px 16px 16px;
    .containerButton {
      @apply relative w-full h-full;
    }
    .closeButton {
      @apply absolute font-extrabold cursor-pointer;
      top: -10px;
      right: -7px;
    }
    .headerButton {
      @apply w-full flex;
      height: 39px;
    }
    .iconButton {
      @apply h-full flex items-center content-center;
      width: 26px;
    }
    .infoButton {
      @apply h-full text-white;
      padding: 0px 7px;
      width: calc(100% - 26px);
      font-size: 12px;
      line-height: 12px;
    }

    .containerInput {
      @apply rounded-md bg-white mt-2 flex;
      width: 100%;
      height: 40px;
      border: 1px solid constants.$nudos-gray-400;
      input::placeholder {
        @apply text-xs;
        line-height: 14px;
        color: #ececec;
      }
    }
    .inputEmail {
      @apply w-full rounded-md p-2 text-xs h-full;
      width: calc(100% - 31px);
    }
    .emailIcon {
      @apply h-full cursor-pointer;
      width: 31px;
    }
    .positionIcon {
      margin-top: 12px;
    }
  }
  #customHeader {
    @apply w-full;
    .spaceHeader {
      @apply w-full;
      height: 75px;
    }
    .containerFloatButton {
      @apply fixed z-30 right-8;
      bottom: 115px;
    }
    .floatHeader {
      @apply w-full fixed left-0 top-0 bg-white z-50;
      height: 75px;
      .containerHeader {
        @apply h-full w-full;
      }
      .centerHeader {
        @apply block m-auto w-full h-full relative;
        padding: 0px 22px 0px 22px;
        max-width: 1024px;
      }
      .iconHeader {
        @apply flex items-center content-center h-full cursor-pointer;
      }
      .containerMenu {
        @apply absolute h-full top-0;
        right: 22px;
        min-width: 10px;
      }
      .containerSimpleItems {
        @apply items-center content-center flex h-full;
      }
      .boxItems {
        @apply flex;
      }
      .simpleItem {
        @apply text-sm cursor-pointer relative text-nudos-gray-400;
        letter-spacing: -0.02em;
        line-height: 16px;
      }
      .simpleItem:hover {
        @apply text-nudos-orange;
      }
      .floatMenu {
        @apply absolute top-0 z-10 rounded-md pt-8;
        left: 0px;
        width: 176px;
        min-height: 10px;
      }
      .containerFloat {
        @apply w-full h-full rounded-md bg-white;
        min-height: 10px;
        padding: 16px 20px 18px 20px;
        filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.1));
      }
      .itemFloatMenu {
        @apply text-xs font-normal block mb-2;
        color: constants.$nudos-green;
        line-height: 16px;
        letter-spacing: -0.02em;
        &:last-child {
          margin-bottom: 0px;
        }
        &.active {
          @apply font-extrabold;
        }
      }
      .iconSmile {
        @apply inline ml-1;
      }
      .selectSimpleItem {
        @apply font-extrabold text-nudos-orange;
      }
      .spaceItem {
        margin-left: 92px;
      }
      .containerHeaderButton {
        @apply h-full flex items-center content-center;
        margin-left: 60px;
      }
      .buttonHeader {
        @apply rounded-md cursor-pointer;
        height: 37px;
        width: 101px;
        border: 1px solid #e46d57;
        .textButtonHeader {
          @apply text-sm text-nudos-orange;
          line-height: 24px;
        }
      }
      .buttonHeader:hover {
        @apply bg-nudos-orange;
        .textButtonHeader {
          @apply text-sm text-white;
          line-height: 24px;
        }
      }

      .iconButtonHeader {
        @apply mr-1;
        height: 20px;
        width: 20px;
        display: inline;
      }
    }
    .languageButtonContainer {
      @apply h-full flex;
      width: 44px;
      height: 20px;
      margin-left: 88px;
      .esButton {
        @apply h-full bg-nudos-orange cursor-pointer flex items-center content-center justify-center;
        padding-left: 1px;
        width: 22px;
        min-width: 22px;
        border-radius: 4px 0px 0px 4px;
      }
      .esButtonAlternative {
        @apply h-full bg-white cursor-pointer flex items-center content-center justify-center;
        width: 22px;
        min-width: 22px;
        border: 1px solid #f27058;
        border-radius: 4px 0px 0px 4px;
      }
      .enButton {
        @apply h-full bg-white cursor-pointer flex items-center content-center justify-center;
        width: 22px;
        min-width: 22px;
        border: 1px solid #f27058;
        border-radius: 0px 4px 4px 0px;
      }
    }
  }
  #customHeaderMobile {
    @apply w-full;
    .spaceHeader {
      @apply w-full;
      height: 64px;
    }
    .floadMobileHeader {
      @apply fixed top-0 left-0 w-full bg-white;
      height: 64px;
      z-index: 100;
    }
    .containerFloatButton {
      @apply fixed z-10;
      bottom: 115px;
      right: 18px;
    }
    .containerMenu {
      @apply w-full h-full flex relative;
      padding: 0px 18px 0px 18px;
    }
    .containerIcon {
      @apply h-full flex items-center content-center cursor-pointer;
    }
    .containerItemsMenu {
      @apply flex items-center content-center justify-end w-full;
    }

    .minArrow {
      @apply inline ml-1;
    }
    .styleItems {
      @apply text-xs mr-6 relative;
      line-height: 16px;
      letter-spacing: -0.02em;
      color: constants.$nudos-green;
      &:last-child {
        margin-right: 0px;
      }
    }
    .floatMobile {
      @apply absolute top-0 left-0 pt-6;
      width: 148px;
      min-height: 10px;
    }
    .containerItems {
      @apply bg-white z-10 rounded-md;
      min-height: 10px;
      width: 148px;
      padding: 16px 20px 18px 20px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .itemFloatMenu {
      @apply text-xs font-normal block mb-2;
      color: constants.$nudos-green;
      line-height: 16px;
      letter-spacing: -0.02em;
      &:last-child {
        margin-bottom: 0px;
      }
      &.active {
        @apply font-extrabold;
      }
    }
  }
  #newThird {
    @apply w-full;
    min-height: 100px;
    .spaceThird {
      padding: 104px 22px 0px 22px;
    }
    .title {
      @apply font-extrabold text-center;
      color: constants.$nudos-orange;
      font-size: 40px;
      line-height: 60px;
      letter-spacing: -0.02em;
      margin-bottom: 48px;
    }
    .exeptionTitle {
      @apply text-black;
    }
    .centerCards {
      margin-bottom: 60px;
      @apply justify-center flex;
    }
    .containerCards {
      @apply flex;
    }
    .cardInfo {
      @apply bg-white;
      width: 480px;
      height: 342px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
      border-radius: 14px;
    }
    .headerCard {
      height: 171px;
      @apply w-full;
      border-radius: 14px 14px 0px 0px;
    }
    .containerIconHeader {
      @apply h-full w-full flex items-center content-center;
    }
    .imgBox {
      @apply justify-center flex w-full;
      height: 117px;
    }
    .positionImg {
      max-height: 100%;
    }
    .containterDownCard {
      @apply relative;
      padding-top: 30px;
      height: calc(100% - 172px);
    }
    .titleDown {
      @apply text-lg font-extrabold text-center;
      color: #383838;
      line-height: 22px;
    }
    .subtitleDown {
      @apply text-sm text-black text-center;
      padding: 0px 76px 0px 76px;
      margin-top: 8px;
      line-height: 14px;
    }
    .containerButton {
      @apply flex justify-center absolute w-full;
      bottom: 16px;
    }
    .whiteButton {
      @apply rounded-md text-xs font-extrabold;
      width: 280px;
      height: 38px;
      border: 1px solid constants.$nudos-orange;
      color: constants.$nudos-orange;
      line-height: 17px;
    }
    .orangeButton {
      @apply rounded-md text-xs font-extrabold text-white border-none;
      background-color: constants.$nudos-orange;
      width: 280px;
      height: 38px;
      line-height: 17px;
    }
    .spaceCards {
      @apply w-5;
    }
    .finalBannerContainer {
      @apply w-full relative rounded-xl;
      background-color: rgba(230, 210, 253, 0.5);
      height: 210px;
    }
    .finalBanerBox {
      @apply h-full absolute right-0 top-0;
      width: calc(100% - 78px);
    }
    .containerItemsBanner {
      @apply w-full h-full relative flex;
    }
    .containerLogo {
      @apply flex items-center content-center h-full;
      margin-right: 118px;
    }
    .containerButtonBanner {
      padding-top: 56px;
    }
    .titleBanner {
      @apply text-sm mb-1;
      line-height: 16px;
    }
    .subtitleBanner {
      @apply font-extrabold text-lg;
      line-height: 22px;
      color: #383838;
    }
    .buttonBoxBanner {
      margin-top: 21px;
    }
    .buttonBanner {
      @apply border-none rounded-md text-white font-extrabold cursor-pointer;
      font-size: 12px;
      background: linear-gradient(
          0deg,
          constants.$nudos-orange,
          constants.$nudos-orange
        ),
        #ffffff;
      border: 1px solid constants.$nudos-orange;
      width: 280px;
      height: 38px;
    }
    .positionEndImg {
      @apply absolute h-full right-4 top-6;
      position: absolute;
    }
    .imgDash {
      max-height: 80%;
    }
  }

  #newThirdMobile {
    @apply w-full;
    .spaceMobile {
      @apply w-full;
      padding: 64px 18px 0px 18px;
    }
    .titleThird {
      @apply font-extrabold text-center text-2xl mb-2;
      color: constants.$nudos-orange;
      letter-spacing: -0.02em;
      line-height: 60px;
    }
    .exeptionTitle {
      @apply text-black;
    }
    .containerAllCards {
      @apply w-full;
      margin-bottom: 55px;
    }
    .containerCard {
      @apply w-full flex justify-center;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
    .cardBox {
      @apply w-full;
      max-width: 340px;
      height: 338px;
      border-radius: 14px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    }
    .headerCard {
      @apply w-full flex items-center content-center;
      height: 169px;
      border-radius: 14px 14px 0px 0px;
    }
    .containerIconOptions {
      @apply w-full flex justify-center;
    }
    .footerCar {
      @apply w-full bg-white relative;
      height: calc(100% - 169px);
      border-radius: 0px 0px 14px 14px;
      padding: 20px 19px 0px 19px;
    }
    .titleCard {
      @apply font-extrabold text-lg text-center;
      margin-bottom: 7px;
      color: #383838;
      line-height: 22px;
    }
    .subtitleCard {
      @apply text-sm text-center text-black;
      line-height: 16px;
      letter-spacing: -0.02em;
    }
    .positionButton {
      @apply absolute w-full right-0;
      height: 38px;
      bottom: 16px;
    }
    .containerButton {
      @apply w-full h-full flex justify-center;
    }
    .whiteMobile {
      @apply w-full rounded-md font-extrabold text-xs bg-white;
      max-width: 280px;
      border: 1px solid constants.$nudos-orange;
      line-height: 17px;
      color: constants.$nudos-orange;
    }
    .orangeMobile {
      @apply w-full rounded-md font-extrabold text-xs text-white;
      max-width: 280px;
      border: none;
      line-height: 17px;
      background-color: constants.$nudos-orange;
    }
    .containerEndInfo {
      @apply w-full flex justify-center;
    }
    .groupItems {
      @apply flex w-full mb-3;
      max-width: 351px;
      height: 152px;
    }
    .groupIcon {
      @apply h-full flex items-center content-center;
      width: 72px;
    }
    .groupImg {
      @apply h-full items-center content-center flex;
      width: calc(100% - 72px);
    }
    .containerEndImg {
      @apply flex justify-end;
    }
    .endImg {
      max-width: 90%;
    }
    .final {
      @apply w-full;
      padding-top: 17px;
      height: 334px;
      background: #e6d2fd;
    }

    .titleNodi {
      @apply text-sm text-center;
      line-height: 16px;
    }
    .subtitleNodi {
      @apply font-extrabold text-lg text-center;
      color: #383838;
      line-height: 22px;
    }
    .containerFinalButton {
      @apply w-full flex justify-center mt-8;
    }
    .finalButton {
      @apply w-full font-extrabold text-white text-xs rounded-md;
      line-height: 16px;
      max-width: 340px;
      height: 48px;
      background-color: constants.$nudos-orange;
    }
  }

  #dynamicBanners {
    @apply w-full;
    .titleCourusel {
      @apply font-extrabold text-xs leading-3 md:text-lg md:leading-5 text-center text-nudos-text-color;
      letter-spacing: -0.02em;
    }
    .generalCarousel {
      @apply w-full flex justify-center;
      padding-top: 25px;
      padding-bottom: 95px;
      height: 33px;
    }

    .containerCarousel {
      @apply w-full relative;
      height: 33px;
      max-width: 1024px;
    }
    .contianerAbsolute {
      @apply h-full absolute top-0;
      left: -206px;
      width: 1420px;
    }
    .blurContainer {
      @apply w-full h-full relative;
    }
    .firstBlur {
      @apply absolute top-0 left-0 z-10 max-h-full;
    }
    .secondBlur {
      @apply absolute top-0 right-0 z-10 max-h-full;
    }
    .sizeContainerBrand {
      @apply h-full flex items-center content-center;
      margin-right: 70px;
      @media (max-width: 768px) {
        margin-right: 35px;
      }
    }
    .sizeImgBrand {
      max-height: 33px;
    }
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* library modal */
.alert-main {
  animation: fadeIn ease 0.5s;
  width: $alert-width;
  margin: auto;
  padding: 1%;
  position: fixed;
  background-color: constants.$pink-color;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 10;
  top: 20vh;
  left: calc(50vw - calc($alert-width / 2));
  border-radius: 20px;
}

.alert-header {
  font-size: 26px;
  font-weight: 600;
}

.alert-body {
  text-align: center;
  font-size: 17px;
}

.alert-button {
  display: flex;
  justify-content: center;
  text-decoration: none;
  color: white !important;
  border-radius: 40px;
  font-size: 17px;
  font-weight: 600;
  border-radius: 10px;
  align-items: center;
  position: relative;
  margin-top: 20px;
  padding: 10px 20px 10px 20px;
}

.backdrop {
  animation: fadeIn ease 1s;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.nudos-orange {
  color: constants.$nudos-orange !important;
}

#nudosModal {
  @apply fixed top-0 left-0 w-screen h-screen flex items-center justify-center;
  z-index: 500;
  background: rgba(0, 0, 0, 0.6);
}

.animatedOnHoverOrangeButton {
  &:hover {
    min-width: 282px;
    max-width: 282px;
    height: 40px;
  }
  @media (max-width: 767px) {
    &:hover {
      min-width: 340px;
      max-width: 340px;
      height: 40px;
    }
  }
}

.animatedBlackButton {
  &:hover {
    &.blackBorder,
    &.blackContent {
      @apply bg-nudos-orange text-white border-nudos-orange;
    }
  }
}
