.homeFirst {
  @apply pt-12 pb-12 flex;

  .leftSection {
    @apply relative;
    padding-top: 110px;
    .titleContainer {
      @apply mb-9 font-extrabold;
      width: 520px;
      font-size: 56px;
      line-height: 56px;
      .title1,
      .title3 strong {
        @apply text-nudos-orange;
      }
    }

    .subtitleContainer {
      @apply mb-8 text-xl leading-6;
      width: 460px;
    }

    .nudosButton {
      @apply mb-3;
    }
  }

  .rightSection {
    img {
      @apply relative -left-12 -top-3;
      min-width: 828px;
      min-height: 734px;
    }
  }

  // MOBILE
  @media (max-width: 767px) {
    @apply block pt-0;
    padding-bottom: 64px;

    .topSection {
      @apply relative left-8;
    }
    .leftSection {
      @apply pt-2;
      padding-left: 18px;
      padding-right: 18px;
      .titleContainer {
        @apply w-auto mb-6 font-extrabold;
        font-size: 40px;
        line-height: 40px;
      }

      .subtitleContainer {
        @apply mb-7 text-base leading-5 tracking-wide;
        width: 340px;
      }

      .nudosButton {
        @apply mx-auto;
        min-width: 338px;
        max-width: 338px;
      }
    }
  }

  // LARGE SCREENS
  @media (min-width: 1420px) {
    .leftSection {
      left: -60px;
    }
    .rightSection {
      img {
        left: -124px;
      }
    }
  }
}
