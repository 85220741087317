.homeFourth {
  // MOBILE FIRST
  @apply pt-16;
  .sectionTitle {
    @apply mx-auto mb-16 text-center text-xl leading-7;
    width: 330px;
    .orangeText {
      @apply text-nudos-orange font-semibold;
    }
  }
  .informationBoxesContainer {
    .informationBox {
      @apply relative mx-auto mb-14 bg-nudos-text-color rounded-2xl border-2 border-solid border-nudos-gray-400 text-white text-center;
      width: 312px;
      height: 130px;
      padding: 48px 12px 20px 12px;
      .boxIcon {
        @apply absolute;
        width: 64px;
        top: -32px;
        left: 124px;
      }
      .boxTitle {
        @apply text-base leading-6 font-bold;
      }
      .boxText {
        @apply text-sm;
        line-height: 17px;
        width: 276px;
      }
    }
  }
  .blueButton {
    @apply -mt-4 mx-auto px-2 flex items-center justify-center bg-nudos-blue rounded-md text-white text-sm leading-3;
    width: 168px;
    height: 32px;
    gap: 6px;
  }

  // DESKTOP
  @apply pt-12;
  @media (min-width: 1024px) {
    .sectionTitle {
      @apply w-auto mb-12 tracking-wide;
    }
    .informationBoxesContainer {
      @apply flex flex-wrap items-center justify-center;
      width: 1024px;
      gap: 42px;
      .informationBox {
        @apply mx-0;
        margin-bottom: 22px;
      }
    }
    .blueButton {
      @apply mt-0;
    }
  }
}
