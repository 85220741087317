.homeSecond {
  // MOBILE FIRST
  .orangeText {
    @apply text-nudos-orange;
  }

  .sectionTitle {
    @apply mx-auto mb-4 text-center font-extrabold;
    width: 332px;
    font-size: 32px;
    line-height: 33px;
  }

  .sectionSubtitle {
    @apply mx-auto mb-8 text-center text-lg;
    width: 250px;
    line-height: 22px;
  }

  .informationBoxes {
    @apply relative;
    margin-bottom: 100px;
    .connectorBackgroundImage {
      @apply absolute;
      top: 108px;
      left: calc(50% - 10px);
    }
    .informationBox {
      @apply relative z-10 mx-auto mb-8 bg-nudos-gray-100 border-2 border-solid border-nudos-text-color rounded-2xl;
      width: 312px;
      height: 227px;
      padding: 104px 24px 20px 24px;
      .icon {
        @apply absolute top-8 left-6;
      }
      .title {
        @apply text-xl leading-7 font-bold;
      }
      .text {
        @apply text-base leading-6;
      }
    }
  }

  .nudosIsTheAnswer {
    .title {
      @apply relative mx-auto font-bold text-center;
      width: 320px;
      margin-bottom: 84px;
      font-size: 32px;
      line-height: 33px;
      .curvyArrowUnderline {
        @apply absolute;
        left: -26px;
        bottom: -12px;
      }
      &.english {
        width: 360px;
      }
    }
    .subtitle {
      @apply relative;
      .backgroundImage {
        @apply absolute;
        top: -40px;
        left: calc(50% - 104px);
        width: 194px;
      }
      .nodiIcon {
        @apply relative z-10 mb-2;
        left: calc(50% - 49px);
      }
      .text {
        @apply mx-auto relative z-10 text-center text-xs font-bold;
        width: 330px;
        margin-bottom: 100px;
        line-height: 18px;
      }
    }
  }

  // DESKTOP
  @media (min-width: 1024px) {
    @apply mt-20;
    .sectionTitle {
      @apply w-auto leading-10;
      font-size: 40px;
    }

    .sectionSubtitle {
      @apply w-auto mx-auto mb-16 text-xl leading-6;
    }

    .informationBoxes {
      @apply flex items-center justify-center;
      margin-bottom: 308px;
      gap: 42px;
      .connectorBackgroundImage {
        top: 226px;
        left: calc(50% - 358px);
      }
    }

    .nudosIsTheAnswer {
      .title {
        width: 600px;
        margin-bottom: 68px;
        font-size: 56px;
        line-height: 58px;
        .curvyArrowUnderline {
          @apply absolute;
          min-width: 220px;
          max-width: 220px;
          left: 0px;
          bottom: 8px;
        }
        &.english {
          width: 750px;
        }
      }
      .subtitle {
        @apply tracking-wide;
        .backgroundImage {
          top: -32px;
          left: calc(50% - 112px);
          width: 215px;
        }
        .text {
          @apply text-2xl;
          width: 660px;
          margin-bottom: 68px;
          line-height: 34px;
        }
      }
    }
  }
}
