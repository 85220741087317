.promotionModal {
  @apply bg-white flex rounded-md select-none text-nudos-text-color;
  width: 580px;
  height: 446px;

  .firstSection {
    @apply bg-cover bg-center bg-no-repeat bg-nudos-gray-200;
    width: 280px;
    height: 446px;
    border-radius: 6px 0px 0px 6px;
  }

  .secondSection {
    @apply relative;
    width: 300px;
    padding: 74px 44px 64px 44px;

    .xSign {
      @apply absolute top-5 right-6 select-none cursor-pointer;
    }

    .explanation {
      @apply mb-7 text-xs text-center font-bold;
      width: 224px;
      line-height: 14px;
    }

    .nudosTextInput {
      @apply mb-3;
    }

    .nudosPhoneInput {
      .nudosTextInput {
        @apply mb-0;
      }
    }
  }

  .buttonContainer {
    @apply w-full mt-4 flex items-center justify-end;
  }

  // MOBILE
  @media (max-width: 767px) {
    @apply block rounded-md;
    width: 340px;
    height: 558px;

    .firstSection {
      @apply relative;
      width: 340px;
      height: 192px;
      border-radius: 6px 6px 0px 0px;
      .xSign {
        @apply absolute top-5 right-5 select-none cursor-pointer;
        path {
          @apply fill-white stroke-white;
        }
      }
      .nudosIcon {
        @apply absolute top-3 left-3 select-none cursor-pointer;
        svg {
          transform: matrix(0.5, 0, 0, 0.5, 0, 0);
          -ms-transform: matrix(0.5, 0, 0, 0.5, 0, 0);
          -webkit-transform: matrix(0.5, 0, 0, 0.5, 0, 0);
        }
      }
      .discountText {
        @apply mx-auto text-base leading-4 text-center font-bold;
        width: 270px;
        padding-top: 18px;
        .line2 {
          @apply relative text-nudos-orange;
          .curvyArrowUnderline {
            @apply absolute;
            min-width: 100px;
            max-width: 100px;
            left: 130px;
            bottom: -26px;
          }
          &.english .curvyArrowUnderline {
            min-width: 90px;
            max-width: 90px;
            left: 110px;
            bottom: -22px;
          }
        }
      }
    }

    .secondSection {
      width: 340px;
      height: 366px;
      padding: 32px 30px 26px 30px;
      border-radius: 0px 0px 6px 6px;

      .explanation {
        @apply mb-7 mx-auto;
        width: 284px;
      }

      .phoneInput {
        .nudosTextInput {
          width: 100%;
        }
      }
    }

    .buttonContainer {
      @apply mt-6 justify-center;
    }
  }
}
