.nudosButton {
  @apply w-full flex items-center justify-center gap-2 border border-solid rounded-md text-xs leading-3 font-extrabold select-none;
  height: 38px;
  @apply bg-nudos-orange border-nudos-orange text-white; //Primary styles
  &:disabled {
    @apply bg-nudos-gray-250 border-nudos-gray-250 text-nudos-gray-400 cursor-not-allowed;
  }
  &.secondary {
    @apply bg-white text-nudos-orange;
    &:disabled {
      @apply border-nudos-gray-300 text-nudos-gray-400;
    }
  }
  &.blackBorder {
    @apply bg-white text-nudos-text-color border-nudos-text-color;
    &:disabled {
      @apply border-nudos-gray-300 text-nudos-gray-400;
    }
  }
  &.blackContent {
    @apply bg-nudos-text-color border-nudos-text-color text-white;
    &:disabled {
      @apply border-nudos-gray-300 text-nudos-gray-400;
    }
  }
  .iconContainer {
    @apply overflow-hidden;
    max-width: 16px;
    max-height: 16px;
  }
  &.extraSmall {
    max-width: 80px;
  }
  &.small {
    max-width: 180px;
  }
  &.medium {
    max-width: 280px;
  }
  &.large {
    max-width: 380px;
  }
}
