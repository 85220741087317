.homeSixthCenterSections {
  @apply bg-nudos-text-color;
}

.homeSixth {
  padding: 84px 0px 40px 0px;
  .title {
    @apply text-nudos-orange font-extrabold;
    margin-bottom: 124px;
    margin-left: 8px;
    font-size: 40px;
    line-height: 28px;

    .whiteText {
      @apply relative text-white;
      .curvyArrowUnderline {
        @apply absolute;
        width: 180px;
        max-width: 180px;
        left: -54px;
        bottom: -16px;
      }
    }
  }

  .stepsContainer {
    @apply flex items-center justify-center gap-6;
    margin-bottom: 124px;
    .stepBox {
      @apply relative bg-white;
      padding-top: 14px;
      width: 180px;
      height: 60px;
      border-radius: 4.6px;

      .textWrapper {
        @apply pl-6 flex gap-3 items-center text-sm leading-4 text-nudos-orange;
        .boxText {
          @apply text-center;
          width: 84px;
        }
        .number {
          @apply font-extrabold;
        }
      }

      .outsideText {
        @apply absolute text-white text-center font-light text-xs leading-3;
        width: 191px;
        margin-left: -4px;
        &.english {
          width: 190px;
        }
        &.outsideText1,
        &.outsideText3,
        &.outsideText5 {
          top: 68px;
        }
        &.outsideText2 {
          top: -32px;
        }
        &.outsideText4 {
          top: -42px;
          &.english {
            top: -32px;
          }
        }
      }

      .absoluteIcon {
        &.absoluteIcon1 {
          @apply absolute;
          width: 44px;
          height: 50px;
          bottom: -12px;
          right: -12px;
        }
        &.absoluteIcon3 {
          @apply absolute;
          width: 49px;
          height: 49px;
          top: -26px;
          right: -1px;
        }
        &.absoluteIcon5 {
          @apply absolute;
          width: 49px;
          height: 49px;
          top: -24px;
          right: -10px;
        }
      }

      .connectingArrow {
        @apply absolute;
        &.connectingArrow1,
        &.connectingArrow3 {
          top: 76px;
          left: 90px;
        }
        &.connectingArrow2,
        &.connectingArrow4 {
          top: -68px;
          left: 80px;
        }
      }
    }
  }

  .quote {
    @apply flex items-center justify-center;
    em {
      @apply mx-auto text-white font-normal text-sm leading-4 text-center;
    }
  }

  // MOBILE
  @media (max-width: 1000px) {
    padding: 44px 52px 36px 52px;
    .title {
      @apply ml-0 mb-10 flex gap-2 items-center justify-center;
      font-size: 24px;
      line-height: 44px;

      .whiteText {
        .curvyArrowUnderline {
          width: fit-content;
          max-width: fit-content;
          bottom: -24px;
          left: -24px;
          transform: scaleY(1);
        }
      }
    }

    .stepsContainer {
      @apply block mx-auto;
      width: 271px;
      margin-bottom: 76px;
      .stepBox {
        @apply mb-14 py-1 rounded-md;
        width: 271px;
        height: 34px;

        .textWrapper {
          @apply pl-5 flex gap-3 items-center text-base text-nudos-orange;
          line-height: 24px;
          .boxText {
            @apply basis-4/5 text-center;
          }
          .number {
            @apply font-extrabold;
          }
        }

        .outsideText {
          @apply absolute text-white text-center;
          width: 240px;
          margin-left: 15px;
          font-size: 10px;
          line-height: 10px;
          &.english {
            width: 240px;
          }
          &.outsideText1,
          &.outsideText2,
          &.outsideText3,
          &.outsideText4,
          &.outsideText5 {
            top: 38px;
          }
          &.outsideText1 {
            width: 240px;
          }
          &.outsideText2 {
            width: 240px;
          }
          &.outsideText3 {
            width: 240px;
          }
          &.outsideText4 {
            width: 240px;
            &.english {
              top: 38px;
            }
          }
          &.outsideText5 {
            width: 240px;
          }
        }

        .absoluteIcon {
          &.absoluteIcon1 {
            @apply absolute;
            width: 44px;
            height: 50px;
            bottom: -18px;
            right: -12px;
          }
          &.absoluteIcon3 {
            @apply absolute;
            width: 49px;
            height: 49px;
            top: -26px;
            right: -5px;
          }
          &.absoluteIcon5 {
            @apply absolute;
            width: 49px;
            height: 49px;
            top: -24px;
            right: -20px;
          }
        }
        .connectingArrow {
          @apply hidden;
        }
      }
    }
  }
}
