.nudosNotification {
  @apply relative px-4 pr-6 py-3 rounded-md bg-nudos-blue text-white text-xs font-semibold;
  width: 364px;
  line-height: 14px;

  .buttonsContainer {
    @apply mt-2 flex items-center justify-center gap-2;
    .button1,
    .button2 {
      @apply w-full rounded-md bg-white text-nudos-blue;
      height: 18px;
      font-size: 10px;
      line-height: 10px;
    }
  }

  .closeAlertButton {
    @apply absolute right-3 top-3;
    .path1,
    .path2 {
      @apply stroke-nudos-blue;
    }
  }

  &.errorNotification {
    background-color: red;

    .buttonsContainer {
      .button1,
      .button2 {
        color: red;
      }
    }

    .closeAlertButton {
      .path1,
      .path2 {
        stroke: red;
      }
    }
  }
}
