.blogListSecondaryCard {
  @apply mb-6 rounded-md text-nudos-text-color select-none cursor-pointer;
  width: 280px;
  height: 340px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  .topSection {
    @apply w-full bg-cover bg-center bg-no-repeat bg-nudos-gray-200;
    border-radius: 6px 6px 0px 0px;
    height: 140px;
  }
  .bottomSection {
    @apply w-full;
    padding: 16px 15px 16px 15px;
    height: 200px;
    .categoryAndTime {
      @apply mb-2 flex gap-1 leading-3;
      font-size: 10px;
      .category {
        @apply text-nudos-orange font-extrabold;
      }
      .readingTime {
        @apply text-nudos-gray-500;
      }
    }
    .postTitle {
      @apply pb-4 border-b border-solid border-nudos-gray-200 text-base leading-5;
    }
    .description {
      @apply mt-4 text-xs leading-4 text-nudos-gray-500;
    }
  }

  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  }

  //MOBILE
  @media (max-width: 767px) {
    @apply mb-0;
    width: 160px;
    height: 330px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    .bottomSection {
      .categoryAndTime {
        @apply mb-2 block;
        font-size: 10px;
      }
      .postTitle {
        @apply pb-3 text-xs leading-3;
      }
      .description {
        @apply mt-2;
        font-size: 10px;
        line-height: 10px;
      }
    }
  }
}
