#menu {
  @apply h-screen w-screen fixed;
  z-index: 1000;
  .headerMenu {
    @apply w-full flex items-center content-center justify-end;
    padding: 0px 18px 0px 18px;
    height: 64px;
    .floatCloseMenu {
      @apply absolute left-0 cursor-pointer;
      width: 100px;
      height: 64px;
    }
  }
  .bodyMenu {
    @apply w-full bg-white;
    padding: 0px 26px 0px 26px;
    height: calc(100vh - 64px);
    opacity: 0.98;
  }
  .itemMenu {
    border-bottom: 2px solid #f0f0f0;
  }
  .boxMenu {
    @apply text-base font-normal text-nudos-gray-400;
    padding: 32px 0px 32px 0px;
    font-size: 16px;
  }
  .selectBoxMenu {
    @apply font-extrabold text-nudos-orange;
  }
  .servicesList {
    @apply pb-8;
  }
  .itemList {
    @apply text-xs text-nudos-green mb-4;
    line-height: 16px;
    letter-spacing: -0.02em;
    &:last-child {
      margin-bottom: 0px;
    }
  }
  .arrowStyle {
    @apply inline-block ml-0.5 relative;
    bottom: 2px;
  }
  .containerMenuButtons {
    @apply w-full flex relative;
    margin-top: 96px;
  }
  .buttonNodi {
    @apply rounded-md bg-white text-sm text-nudos-orange;
    width: 160px;
    height: 37px;
    border: 1px solid #e46d57;
  }
  .containerButtosLanguage {
    @apply flex absolute right-0;
    height: 38px;
    width: 90px;
    .espButton {
      @apply h-full bg-nudos-orange items-center content-center flex justify-center cursor-pointer;
      width: 45px;
      border-radius: 4px 0px 0px 4px;
    }

    .alertativeEsButton {
      @apply h-full bg-white items-center content-center flex justify-center cursor-pointer;
      width: 45px;
      border: 1px solid #f27058;
      border-radius: 4px 0px 0px 4px;
    }
    .engButton {
      @apply h-full bg-white items-center content-center flex justify-center cursor-pointer;
      width: 45px;
      border: 1px solid #f27058;
      border-radius: 0px 4px 4px 0px;
    }

    .alernativeEnButton {
      @apply h-full bg-nudos-orange items-center content-center flex justify-center cursor-pointer;
      width: 45px;
      border-radius: 0px 4px 4px 0px;
    }
  }
}
