.nudosSelectDropdown {
  @apply text-nudos-gray-300;
  .selectLabel {
    @apply mb-1 select-none;
    font-size: 10px;
    line-height: 10px;
  }
  .selectContainer {
    @apply relative;
    .closedSelectInput {
      @apply w-full px-4 flex items-center justify-between gap-2 border border-solid border-nudos-gray-300 rounded-md text-xs leading-3 select-none cursor-pointer;
      height: 38px;
      .currentValueOrplaceholder {
        @apply overflow-x-hidden text-ellipsis whitespace-nowrap;
        height: 14px;
      }
      .expandArrow {
        svg {
          margin-top: 2px;
          margin-left: -2px;
          transform: matrix(0, -0.55, 0.55, 0, 0, 0);
          -ms-transform: matrix(0, -0.55, 0.55, 0, 0, 0);
          -webkit-transform: matrix(0, -0.55, 0.55, 0, 0, 0);
          path {
            @apply stroke-nudos-gray-300 fill-nudos-gray-300;
          }
        }
      }
      .contractArrow {
        svg {
          transform: matrix(0, 0.55, -0.55, 0, 0, 0);
          -ms-transform: matrix(0, 0.55, -0.55, 0, 0, 0);
          -webkit-transform: matrix(0, 0.55, -0.55, 0, 0, 0);
          path {
            @apply stroke-nudos-gray-300 fill-nudos-gray-300;
          }
        }
      }
    }
    .optionsContainer {
      @apply absolute w-full z-30 p-4 pr-2 bg-white rounded-md overflow-y-scroll select-none;
      max-height: 122px;
      box-shadow: 0px 4px 8px rgba(179, 179, 179, 0.15);
      .paddingWrapper {
        @apply overflow-y-auto;
        max-height: 90px;
        &::-webkit-scrollbar {
          @apply relative w-1 rounded-md bg-nudos-gray-100 border-solid border-nudos-gray-200;
          border: 0.2px;
          visibility: visible;
        }
        &::-webkit-scrollbar-thumb {
          @apply rounded-md bg-nudos-gray-300;
          &:hover {
            @apply bg-nudos-gray-500 cursor-pointer;
          }
        }
      }
      .optionContainer {
        @apply pb-3 text-xs leading-3 select-none cursor-pointer;
        color: #383838;
      }
    }
  }
  .errorText {
    @apply mt-1;
    font-size: 10px;
    line-height: 10px;
    color: red;
  }

  &.filled {
    color: #383838;
    .selectContainer {
      .closedSelectInput {
        @apply border-nudos-gray-500;
      }
      .expandArrow {
        svg {
          path {
            stroke: #383838;
            fill: #383838;
          }
        }
      }
      .contractArrow {
        svg {
          path {
            stroke: #383838;
            fill: #383838;
          }
        }
      }
    }
  }

  &.error {
    color: red;
    .selectContainer {
      .closedSelectInput {
        border-color: red;
      }
      .expandArrow {
        svg {
          path {
            stroke: red;
            fill: red;
          }
        }
      }
      .contractArrow {
        svg {
          path {
            stroke: red;
            fill: red;
          }
        }
      }
    }
  }

  &.deactivated {
    @apply text-nudos-gray-400;
    .selectContainer {
      .closedSelectInput {
        @apply border-nudos-gray-400 bg-nudos-gray-100 cursor-not-allowed;
      }
      .expandArrow {
        svg {
          path {
            @apply stroke-nudos-gray-400 fill-nudos-gray-400;
          }
        }
      }
      .contractArrow {
        svg {
          path {
            @apply stroke-nudos-gray-400 fill-nudos-gray-400;
          }
        }
      }
    }
  }

  &.xsSmall {
    width: 80px;
    .selectLabel {
      max-width: 80px;
    }
    .selectContainer {
      .closedSelectInput {
        min-width: 80px;
        max-width: 80px;
      }
      .optionsContainer {
        max-width: 80px;
        .optionContainer {
          max-width: 80px;
        }
      }
    }
    .errorText {
      max-width: 80px;
    }
  }

  &.small {
    width: 180px;
    .selectLabel {
      max-width: 180px;
    }
    .selectContainer {
      .closedSelectInput {
        min-width: 180px;
        max-width: 180px;
      }
      .optionsContainer {
        max-width: 180px;
        .optionContainer {
          max-width: 180px;
        }
      }
    }
    .errorText {
      max-width: 180px;
    }
  }

  &.medium {
    width: 280px;
    .selectLabel {
      max-width: 280px;
    }
    .selectContainer {
      .closedSelectInput {
        min-width: 280px;
        max-width: 280px;
      }
      .optionsContainer {
        max-width: 280px;
        .optionContainer {
          max-width: 280px;
        }
      }
    }
    .errorText {
      max-width: 280px;
    }
  }

  &.large {
    width: 380px;
    .selectLabel {
      max-width: 380px;
    }
    .selectContainer {
      .closedSelectInput {
        min-width: 380px;
        max-width: 380px;
      }
      .optionsContainer {
        max-width: 380px;
        .optionContainer {
          max-width: 380px;
        }
      }
    }
    .errorText {
      max-width: 380px;
    }
  }
}
