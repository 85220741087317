.truckAnimationDesktop {
  @keyframes moveTruck1 {
    0% {
      offset-distance: 0%;
      opacity: 1;
    }
    100% {
      offset-distance: 100%;
    }
  }
  @keyframes moveTruck2 {
    0% {
      offset-distance: 25%;
      opacity: 1;
    }
    74% {
      opacity: 1;
    }
    75% {
      offset-distance: 100%;
      opacity: 0;
    }
    76% {
      offset-distance: 0%;
      opacity: 0;
    }
    77% {
      opacity: 1;
    }
    100% {
      offset-distance: 25%;
    }
  }
  @keyframes moveTruck3 {
    0% {
      offset-distance: 50%;
      opacity: 1;
    }
    49% {
      opacity: 1;
    }
    50% {
      offset-distance: 100%;
      opacity: 0;
    }
    51% {
      offset-distance: 0%;
      opacity: 0;
    }
    52% {
      offset-distance: 0%;
      opacity: 1;
    }
    100% {
      offset-distance: 50%;
      opacity: 1;
    }
  }
  @keyframes moveTruck4 {
    0% {
      offset-distance: 75%;
      opacity: 1;
    }
    24% {
      opacity: 1;
    }
    25% {
      offset-distance: 100%;
      opacity: 0;
    }
    26% {
      offset-distance: 0%;
      opacity: 0;
    }
    27% {
      offset-distance: 0%;
      opacity: 1;
    }
    100% {
      offset-distance: 75%;
    }
  }
  width: 1513px;
  @apply relative;
  height: 260px;

  .trajectory {
    @apply absolute bottom-0;
    left: -200px;
  }
  .greyTruck1,
  .greyTruck2 {
    @apply absolute;
    top: -40px;
    left: -200px;
    transform: rotate(6deg);
    offset-path: path(
      "M-59 262C76.1398 230.832 257.364 167.345 755.461 167.345C1086.66 162.665 1210.87 134.58 1454 3"
    );
  }
  .orangeTruck1,
  .orangeTruck2 {
    @apply absolute;
    top: -38px;
    left: -200px;
    transform: rotate(4deg);
    offset-path: path(
      "M-59 262C76.1398 230.832 257.364 167.345 755.461 167.345C1086.66 162.665 1210.87 134.58 1454 3"
    );
  }
  .greyTruck1 {
    animation: moveTruck1 12000ms infinite linear;
  }
  .orangeTruck1 {
    animation: moveTruck2 12000ms infinite linear;
  }
  .greyTruck2 {
    animation: moveTruck3 12000ms infinite linear;
  }
  .orangeTruck2 {
    animation: moveTruck4 12000ms infinite linear;
  }
}

.truckAnimationMobile {
  @apply relative mx-auto;
  width: 400px;
  height: 170px;

  @keyframes moveTruck1Mobile {
    0% {
      offset-distance: 0%;
      opacity: 1;
    }
    90% {
      offset-distance: 87%;
      opacity: 1;
    }
    95% {
      offset-distance: 90%;
      opacity: 0;
    }
    100% {
      offset-distance: 100%;
      opacity: 0;
    }
  }
  @keyframes moveTruck2Mobile {
    0% {
      offset-distance: 40%;
      opacity: 1;
    }
    50% {
      offset-distance: 87%;
      opacity: 1;
    }
    55% {
      offset-distance: 90%;
      opacity: 0;
    }
    56% {
      offset-distance: 0%;
      opacity: 0;
    }
    57% {
      opacity: 1;
    }
    100% {
      offset-distance: 40%;
    }
  }

  .trajectory {
    @apply absolute bottom-0;
    left: 0px;
    top: 28px;
  }
  .greyTruck1 {
    @apply absolute;
    left: 0px;
    transform: rotate(6deg);
    offset-path: path(
      "M-45 175C-3.37732 154.903 52.4393 113.968 205.852 113.968C307.862 110.95 346.115 92.8414 421 8"
    );
  }
  .orangeTruck1 {
    @apply absolute;
    left: 0px;
    top: 5px;
    transform: rotate(4deg);
    offset-path: path(
      "M-45 175C-3.37732 154.903 52.4393 113.968 205.852 113.968C307.862 110.95 346.115 92.8414 421 8"
    );
  }
  .greyTruck1 {
    animation: moveTruck1Mobile 9000ms infinite linear;
  }
  .orangeTruck1 {
    animation: moveTruck2Mobile 9000ms infinite linear;
  }
}
