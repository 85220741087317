.nodiPlatformThirdCenterSections {
  @apply relative;
  .blueLatamMap {
    @apply absolute top-0;
    left: calc((100% - 1420px) / 2);
    min-width: 1049px;
    max-width: 1049px;
  }
  //MOBILE
  @media (max-width: 899px) {
    .blueLatamMap {
      @apply absolute;
      top: 36px;
      left: -60px;
      min-width: 511px;
      max-width: 511px;
    }
  }
}

.nodiPlatformThird {
  //DESKTOP
  @media (min-width: 900px) {
    padding: 240px 0 164px 0;

    .title {
      @apply relative bg-transparent z-10 mb-11 flex gap-2 items-center justify-center font-extrabold;
      font-size: 40px;
      line-height: 60px;
      strong {
        @apply text-nudos-orange;
      }
      .nudosLogo {
        margin-top: 7px;
        transform: matrix(0.7, 0, 0, 0.7, 0, 0);
        -ms-transform: matrix(0.7, 0, 0, 0.7, 0, 0);
        -webkit-transform: matrix(0.7, 0, 0, 0.7, 0, 0);
      }
    }

    .boxesContainer {
      @apply flex gap-5 items-center justify-center;
      margin-bottom: 70px;
      .advantageBox {
        @apply z-10 border border-solid border-nudos-gray-200 bg-white rounded-md;
        width: 230px;
        height: 282px;
        .boxTitle {
          @apply mb-4 text-xl leading-5 font-extrabold text-center;
        }
        .boxText {
          @apply mx-auto text-sm leading-4 text-center tracking-tight;
          width: 200px;
        }
        .upperContent {
          @apply mb-3 flex items-center justify-center;
        }
        &.advantageBox1 {
          padding-top: 24px;
          padding-bottom: 36px;
          .upperContent {
            .icon {
              width: 118px;
              height: 90px;
            }
          }
          .boxText {
            width: 198px;
          }
        }
        &.advantageBox2 {
          padding-top: 36px;
          padding-bottom: 36px;
          .upperContent {
            .icon {
              width: 120px;
              height: 79px;
            }
          }
          .boxText {
            width: 190px;
          }
        }
        &.advantageBox3 {
          padding-top: 28px;
          padding-bottom: 36px;
          .upperContent {
            .icon {
              width: 100px;
              height: 87px;
            }
          }
          .boxText {
            width: 200px;
          }
        }
        &.advantageBox4 {
          @apply mx-0;
          padding: 35px 0px 20px 0px;
          .upperContent {
            .icon {
              width: 96.5px;
              height: 80px;
            }
          }
          .boxText {
            min-width: 210px;
          }
        }
      }
    }
  }

  // MOBILE
  @media (max-width: 899px) {
    padding: 30px 0 88px 0;

    .title {
      @apply z-10 mb-6 flex gap-1 items-center justify-center font-extrabold;
      font-size: 22px;
      line-height: 44px;
      strong {
        @apply text-nudos-orange;
      }
      .nudosLogo {
        margin-top: 3px;
        margin-left: -8px;
        transform: matrix(0.5, 0, 0, 0.5, 0, 0);
        -ms-transform: matrix(0.5, 0, 0, 0.5, 0, 0);
        -webkit-transform: matrix(0.5, 0, 0, 0.5, 0, 0);
      }
    }

    .boxesContainer {
      @apply block;
      .advantageBox {
        @apply relative z-10 mx-auto mb-2 flex items-center border border-solid border-nudos-gray-200 bg-white rounded-md;
        width: 340px;
        height: 96px;
        .leftContent {
          @apply block;
          width: 104px;
          .icon {
            @apply mx-auto;
          }
        }
        .rightContent {
          width: 236px;
          .boxTitle {
            @apply mb-2 text-base leading-5 font-extrabold text-left;
          }
          .boxText {
            @apply mx-0 text-xs leading-3 text-left;
            width: 228px;
          }
        }
        &.advantageBox1 .leftContent .icon {
          width: 82px;
          height: 62px;
        }
        &.advantageBox2 .leftContent .icon {
          width: 70px;
          height: 46.5px;
        }
        &.advantageBox3 .leftContent .icon {
          width: 60px;
          height: 52px;
        }
        &.advantageBox4 {
          .leftContent .icon {
            width: 68.5px;
            height: 57.5px;
          }
          .rightContent .boxText {
            width: 216px;
          }
        }
      }
    }
  }
}
