$nudos-green: #2c7152;
$nudos-orange: #f27058;
$nudos-blue: #54a1fc;
$nudos-light-blue: #d6e9ff;
$nudos-violet: #e6d2fd;
$nudos-purple: #7b61ff;
$nudos-text-color: #383838;
$nudos-gray-100: #fafafa;
$nudos-gray-200: #f0f0f0;
$nudos-gray-250: #e6e6e6;
$nudos-gray-300: #cccccc;
$nudos-gray-400: #b3b3b3;
$nudos-gray-500: #999999;
$nudos-gray-600: #666666;
$nudos-gray-700: #333333;
$pink-color: #f4cdc0;
$light-green: #b8d19c;
