.floatingPromotionButton {
  @apply fixed z-50 left-0 bg-nudos-text-color text-white text-xs leading-3 font-extrabold select-none cursor-pointer;
  width: 190px;
  height: 32px;
  bottom: 40px;
  padding: 10px;
  border-radius: 0px 0px 4px 4px;
  transform: rotate(-90deg);
  -webkit-transform-origin: 0% 0%;

  //MOBILE
  @media (max-width: 767px) {
    bottom: 0px;
  }
}
